import React from 'react'
import { FaSearch } from 'react-icons/fa';

export default function SearchComponent ({ search, searcher, placeholder, aligment = 'end' }) {
  return (
    <>
      <form className={`flex w-full justify-${aligment}`}>
        <div className="flex w-1/2 sm:w-3/4">
          <label htmlFor="location-search" className="mb-2 text-sm font-medium sr-only">Buscar....</label>
          <div className="relative w-full">
            <input value={search} onChange={searcher} type="search" id="location-search" className="block p-2.5 w-full z-20 text-sm bg-third-color-2 rounded-3xl border border-primary-color focus:bg-white focus:ring-primary-color focus:border-primary-color" placeholder={placeholder} required />
            {/* <button type="submit" className="absolute top-0 end-0 h-full p-2.5 text-lg font-medium text-white bg-primary-color rounded-e-3xl border border-primary-color hover:bg-primary-color focus:ring-1 focus:outline-none focus:ring-primary-color">
              <FaSearch />
              <span className="sr-only">Search</span>
            </button> */}
          </div>
        </div>
      </form>
    </>
  )
}

