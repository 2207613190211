import views from '../views';
import {HOME, LOGIN, SIGNUP, USERPROFILE, PRIVADA,TEAM,SERVICE,CONTACT,PRICING,PROFILE,EXAMEN,DETAILS,CONVOCATORIA,PLANS,SUGGESTIONS, EXAMTEST, MANAGEMENT,LIVE, COUNTS, PAYMENT, DRIVER, PRINT, COPIA, ADMIN } from './paths';
import {REGISTER_TO_EVENT, EVENTS, LICENCIAS, ADMINAFILIATE, TEACHER, SIMPLEPAY} from './paths';

const Home = {
    component: views.Home,
    path:HOME,
    isPrivate: false,
}

const Licencias = {
    component: views.Licencias,
    path:LICENCIAS,
}
/*
const NotFound_ = {
    component: views.NotFound,
    path:'*',
    isPrivate: false,
}
*/
const Payment = {
    component: views.Payment,
    path:PAYMENT,
    isPrivate: false,
}

const UserProfile = {
    component: views.UserProfile,
    path:USERPROFILE,
    isPrivate: false,
}

const RegisterToEvent = {
    component: views.RegisterToEvent,
    path:REGISTER_TO_EVENT,
    isPrivate: false,
}
const Counts = {
    component: views.Counts,
    path:COUNTS,
    isPrivate: false,
}

const Events = {
    component: views.Events,
    path:EVENTS,
    isPrivate: false,
}

const Management = {
    component: views.Management,
    path:MANAGEMENT,
    isPrivate: false,
}

const Login = {
    component: views.Login,
    path:LOGIN,
    isPrivate: false,
}

const Privada = {
    component: views.Privada,
    path:PRIVADA,
    isPrivate: true,
}
const Signup = {
    component: views.Signup,
    path:SIGNUP,
    isPrivate: false,
}
const Team = {
    component: views.Team,
    path:TEAM,
    isPrivate: false,
}
const Service = {
    component: views.Service,
    path:SERVICE,
    isPrivate: false,
}
const Contact = {
    component: views.Contact,
    path:CONTACT,
    isPrivate: false,
}

const Pricing = {
    component: views.Pricing,
    path:PRICING,
    isPrivate: false,
}
const Profile = {
    component: views.Profile,
    path:PROFILE,
    isPrivate: false,
}
const Examen = {
    component: views.Examen,
    path:EXAMEN,
    isPrivate: false,
}
const ExamTest = {
    component: views.ExamTest,
    path:EXAMTEST,
    isPrivate: false,
}
const Details = {
    component: views.Details,
    path:DETAILS,
    isPrivate: false,
}
const Convocatoria = {
    component: views.Convocatoria,
    path:CONVOCATORIA,
    isPrivate: false,
}
const Plans = {
    component: views.Plans,
    path:PLANS,
    isPrivate: false,
}
const Suggestions = {
    component: views.Suggestions,
    path:SUGGESTIONS,
    isPrivate: false,
}
const Live = {
    component: views.Live,
    path:LIVE,
    isPrivate: false,
}
const Driver = {
    component: views.Driver,
    path:DRIVER,
    isPrivate: false,
}

const Print = {
    component: views.Print,
    path:PRINT,
    isPrivate: false,
}

const AdminAfiliate = {
    component: views.AdminAfiliate,
    path:ADMINAFILIATE,
    isPrivate: false,
}

const Copia = {
    component : views.Copia,
    path: COPIA,
    isPrivate: false,
}

const Admin = {
    component : views.Admin,
    path: ADMIN,
    isPrivate: true,
}

const Teacher = {
    component : views.Teacher,
    path: TEACHER,
    isPrivate: false,
}

const SimplePay = {
    component : views.SimplePay,
    path: SIMPLEPAY,
    isPrivate: false,
}

// no poner datos despues de Home [Login,..... , Home ];
const exports = [
    Driver,
    Management,
    Examen,
    ExamTest,
    Login,
    Signup,
    Privada,
    Team,
    Service,
    Contact,
    Pricing,
    Profile,
    Details,
    Convocatoria,
    Plans,
    Suggestions,
    RegisterToEvent,
    Events,
    Live,
    Counts,
    Payment,
    Licencias,
    UserProfile,
    Print,
    AdminAfiliate,
    Copia,
    Admin,
    Teacher,
    SimplePay,
    Home
]

export default exports;
