import React, { useEffect, useState } from 'react';
import { db } from '../../config/firebase';
import dbRef from '../../config/dbRef';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { Link } from "react-router-dom";
import image from '../../assets/images/teacher.webp'
import Footer from '../components/footer';
import {FiClock} from "react-icons/fi";
import {FiClipboard} from "react-icons/fi";
import {AiOutlineFileText} from "react-icons/ai";
import {CgCheck} from "react-icons/cg";
import {BiX} from "react-icons/bi";
import {IoMdRadioButtonOff, IoMdRadioButtonOn, IoMdCheckmark} from "react-icons/io";

const Teacher = () => {

    const [data, setData] = useState([]);

    useEffect(() => {
      const fetchData = async () => {
          try {
              const q = query(
                  collection(db, dbRef.exam),
                  where("area", "==", "teacher") // Filtro con where
              );
              // agregar un id a cada pregunta
              const response = await getDocs(q);
              const dataTeacher = response.docs.map(doc => {
                // agregar un id a cada pregunta
                return { ...doc.data(), id: doc.id };
              }
            ); // Transformación de datos
              setData(dataTeacher); // Establece el estado con los datos filtrados
              console.log(dataTeacher);
          } catch (error) {
              console.error("Error fetching data:", error);
          }
      };
      fetchData();
  }, []);

    return (
        <>
          <div className="flex items-center justify-center flex-row  bg-gray-700 px-20 sm:px-4 md:px-32 py-24 sm:py-24 md:py-8 lg:py-0 lg:px-32">
            <div className='w-1/2 sm:w-full lg:w-1/2'>
                <h1 className="text-primary text-4xl sm:text-center md:text-left lg:text-left" >
                    Apriori te prepara para la Escuela Superior de Formación de Maestros.</h1>
                <p className="font-medium text-2xl leading-10 my-5 w-3/4 text-white sm:text-base md:text-xl lg:text-2xl text-left sm:text-center lg:text-left sm:w-full lg:w-3/4">
                    Ahora, los futuros maestros pueden contar con AprioriBo para prepararse para el examen de ingreso a las ESFM. 
                </p>
                <p className="font-medium text-2xl leading-10 my-5 w-3/4 text-white sm:text-base md:text-xl lg:text-2xl text-left sm:text-center lg:text-left sm:w-full lg:w-3/4">
                ¡Únete a nuestra comunidad de aprendizaje y comienza a alcanzar tus metas!
                </p>
                <a href="#plans" className="bg-primary text-white  px-4 py-2  mx-auto rounded-md mt-4 inline-block hover:bg-primary-dark hover:text-white">Comienza Ahora</a>
            </div>
            <img src={image } alt="Teacher" className="w-1/2 h-full object-contain mt-24 sm:w-0 lg:w-1/2" />
          </div>
          <div className="p-32 items-center bg-fondo sm:p-5 md:p-8 lg:p-32">
              <h2 className="text-3xl font-semibold text-center text-white">Simulacros</h2>
              <div className="flex justify-center items-center m-16 flex-wrap sm:m-4 lg:m-8">
                  {data.map((item, index) => (
                      <Card key={index} data={item} />
                  ))}
              </div>
          </div>
          <p className="text-center text-white bg-primary py-4 font-700 text-2xl">Tu futuro como maestro comienza aquí </p>
          <div id='plans' className="bg-basic p-72 sm:p-2 lg:p-16 md:p-8 flex lg:flex-row flex-row sm:flex-col justify-center items-center">
              
            <div className="bg-basic rounded flex flex-col  justify-center items-center w-3/5 sm:w-full lg:w-3/4 p-24 sm:p-4 sm:py-16 lg:p-8">
              <h2 className="text-3xl font-semibold text-center text-gray-700">
                <span className="text-primary">¿</span> 
                Por qué suscribirme a  
                <span className="text-primary"> AprioriBo?</span> 
              </h2>
              <p className="font-medium text-2xl leading-10 my-5 w-3/4 text-gray-700 sm:text-base md:text-xl lg:text-2xl text-left sm:text-center lg:text-left sm:w-full lg:w-3/4">
              Apriori es la plataforma donde podrás simular tu examen real. Familiarízate con las pruebas. Gestiona mejor tu tiempo y reduce el estrés. 
              <span className="text-primary ml-2">
                 ¡Suscríbete ahora y comienza a prepararte!
              </span>
              </p>
            </div>
            <div className="w-2/5 sm:w-full lg:w-2/5">
              <div className="bg-gray-100 rounded-xl m-24 sm:m-8  flex flex-col justify-center items-center border-2 border-primary ">
                <div className="rounded-xl w-5/6 flex flex-col justify-center items-center border-2 border-primary bg-primary text-white font-700 text-2xl px-4 py-2 -mt-10">	
                  Suscripción
                  <p className="text-3xl font-bold">Bs. <span>50</span></p>
                </div>
                <div className="flex flex-col justify-start items-start mx-4 mt-4 mb-8">
                  <p className="font-bold text-primary text-left text-lg sm:text-base md:text-lg flex items-start mr-2"><FiClipboard className="mr-2 text-primary"/>Total de Intentos: 10</p>
                  <p className="font-regular text-left text-md sm:text-base md:text-lg  ml-3">Más de 40 preguntas en cada examen</p>
                  <p className="font-bold text-primary text-left text-lg sm:text-base md:text-lg flex items-start mr-2 mt-4"><AiOutlineFileText className="mr-2 text-primary"/>Total de Preguntas: </p>
                  <p className="font-regular text-left text-lg sm:text-base md:text-lg ml-3">Más de 500 preguntas</p>
                  <p className="font-bold text-primary text-center text-xl flex items-center mr-2 mt-4 mx-4">Beneficios:</p>
                  <ol  className="mx-4">
                    <li className="font-regular text-left text-lg flex items-start mr-2">
                      <p className={`font-regular text-left text-lg sm:text-base md:text-lg flex items-start mr-2 mt-1 ${!true?'text-gray-400':'text-black'}`}>{true?<CgCheck size={32} className="text-red-500"/>:<BiX/>} Examen cronometrado. </p>
                    </li>
                    <li className="font-regular text-left text-lg flex items-start mr-2">
                      <p className={`font-regular text-left text-lg sm:text-base md:text-lg flex items-start mr-2 mt-1 ${!true?'text-gray-400':'text-black'}`}>{true?<CgCheck size={32} className="text-red-500"/>:<BiX/>} Calificacion inmediata. </p>
                    </li>
                    <li className="font-regular text-left text-lg flex items-start mr-2">
                      <p className={`font-regular text-left text-lg sm:text-base md:text-lg flex items-start mr-2 mt-1 ${!true?'text-gray-400':'text-black'}`}>{true?<CgCheck size={32} className="text-red-500"/>:<BiX/>} Revisión.</p>
                    </li>
                    <li className="font-regular text-left text-lg flex items-start mr-2">
                      <p className={`font-regular text-left text-lg sm:text-base md:text-lg flex items-start mr-2 mt-1 ${!true?'text-gray-400':'text-black'}`}>{true?<CgCheck size={32} className="text-red-500"/>:<BiX/>} Gráficos comparativos.</p>
                    </li>
                    <li className="font-regular text-left text-lg flex items-center mr-2">
                      <p className={`font-regular text-lwft text-lg sm:text-base md:text-lg flex items-left mr-2 mt-1 ${!true?'text-gray-400':'text-black'}`}>{true?<CgCheck size={32} className="text-red-500"/>:<BiX/>} Y mucho mas.</p>
                    </li>
                  </ol>
                    <Link className="bg-primary text-white px-4 py-2 rounded-md mt-4 inline-block hover:bg-primary-dark hover:text-white self-center" to="/simple-pay">Suscribirme</Link>
                </div>
                
              </div>
            </div>
            </div>
            
          <Footer />
        </>
    );
};

export default Teacher;

const Card = ({ data }) => {
  return (
    <div className="sm:w-1/2 p-1 md:w-1/3 lg:w-1/5">
      <div className="bg-white shadow-lg rounded-lg p-4 text-center">
      <h2 className="text-xl font-semibold mb-4 sm:text-sm lg:text-lg">{data.nombre}</h2>
      <p className="text-gray-600 sm:text-sm">{data.descripcion}</p>
      <h3 className="text-lg font-semibold text-primary sm:text-sm sm:hidden block lg:block lg:text-lg">Materias</h3>
      {
        data?.materias?.map((materia, index) => (
          <p key={index} className={`sm:text-sm sm:hidden block lg:block ${ index%2 == 0 ? 'text-gray-500' : 'text-gray-800' }`}>{materia.nombre}</p>
        ))
      }
      <div>
      <Link className="
        bg-primary
        text-white
        px-4
        py-2
        rounded-md
        mt-4
        inline-block
        hover:bg-primary-dark
        hover:text-white
      " to={{
          pathname: `/Details`,
          data: data,
          type: 'teacher'
        }}>Ver Detalles</Link>
      </div>
      </div>
    </div>
  );
}