import { doc, updateDoc } from 'firebase/firestore';
import React, { useState } from 'react'
import { db } from '../../../../config/firebase';

export const ModalUpdatePlan = ({ isOpen, closeModal, plan, idPlan, refresPlans }) => {

    const [formData, setFormData] = useState({
        nombre: plan.nombre,
        precio: plan.precio,
        suscripcion: plan.suscripcion,
        descripcion: plan.descripcion,
        beneficios: {
            calificacionInmediata: { descripcion: plan.calificacionInmediata?.descripcion || "", disponible: plan.calificacionInmediata?.disponible ?? 0 },
            examenCronometrado: { descripcion: plan.examenCronometrado?.descripcion || "", disponible: plan.examenCronometrado?.disponible ?? 0 },
            graficosComparativos: { descripcion: plan.graficosComparativos?.descripcion || "", disponible: plan.graficosComparativos?.disponible ?? 0 },
            modificarFechaExamenFinal: { descripcion: plan.modificarFechaExamenFinal?.descripcion || "", disponible: plan.modificarFechaExamenFinal?.disponible ?? 0 },
            modificarMateriasExamen: { descripcion: plan.modificarMateriasExamen?.descripcion || "", disponible: plan.modificarMateriasExamen?.disponible ?? 0 },
            revision: { descripcion: plan.revision?.descripcion || "", disponible: plan.revision?.disponible ?? 0 }
        },

        pruebasPracticas: {
            cantidadPreguntas: plan.pruebasPracticas.cantidadPreguntas,
            cantidadPruebas: plan.pruebasPracticas.cantidadPruebas,
        },
        pruebasFinales: {
            cantidadPreguntas: plan.pruebasFinales.cantidadPreguntas,
            cantidadPruebas: plan.pruebasFinales.cantidadPruebas,
        },

        totalPreguntas: plan.totalPreguntas,
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const planId = idPlan

        try {

            const beneficiosActualizados = Object.keys(formData.beneficios).reduce((acc, key) => {
                acc[key] = {
                    descripcion: formData.beneficios[key].descripcion || plan.beneficios[key].descripcion,
                    disponible: formData.beneficios[key].disponible,
                };
                return acc;
            }, {});    

            const docRef = doc(db, 'planes', planId);
            await updateDoc(docRef, {
                ...formData,
                beneficios: beneficiosActualizados

            });
            alert('Plan: ' + planId + ' actualizado.');
            refresPlans();
        } catch (error) {
            alert("Error al actualizar los datos: " + error);
            console.log(error);
        }
        closeModal();
    };

    if (!isOpen) return null;
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-6 rounded-lg w-11/12 max-w-full h-9/10 overflow-y-auto">
                <h2 className="text-xl font-bold mb-4">
                    {plan ? 'Editar Plan' : 'Agregar Plan'}
                </h2>
                <form onSubmit={handleSubmit} className="h-full">
                    <div className="grid grid-cols-2 gap-4 mb-4">
                        <div>
                            <label className="block text-gray-700 text-sm font-bold mb-2">Nombre del Plan</label>
                            <input
                                type="text"
                                name="nombre"
                                value={formData.nombre}
                                onChange={handleInputChange}
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                placeholder="Introduce el nombre del plan"
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700 text-sm font-bold mb-2">Precio</label>
                            <input
                                type="number"
                                name="precio"
                                value={formData.precio}
                                onChange={handleInputChange}
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                placeholder="Introduce el precio"
                            />
                        </div>
                        <div className="col-span-2">
                            <label className="block text-gray-700 text-sm font-bold mb-2">Suscripción</label>
                            <input
                                type="text"
                                name="suscripcion"
                                value={formData.suscripcion}
                                onChange={handleInputChange}
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                placeholder="Introduce la suscripción"
                            />
                        </div>
                    </div>

                    {/* Beneficios */}
                    <h3 className="font-bold mb-2">Beneficios</h3>
                    <div className="grid grid-cols-2 gap-4 mb-4">
                    {[
                        { name: 'calificacionInmediata', label: 'Calificación Inmediata' },
                        { name: 'examenCronometrado', label: 'Examen Cronometrado' },
                        { name: 'graficosComparativos', label: 'Gráficos Comparativos' },
                        { name: 'modificarFechaExamenFinal', label: 'Modificar Fecha Examen Final' },
                        { name: 'modificarMateriasExamen', label: 'Modificar Materias Examen' },
                        { name: 'revision', label: 'Revisión' },
                    ].map((beneficio) => (
                        <div key={beneficio.name} className="flex items-center">
                            <label className="text-gray-700 text-sm mr-2">{beneficio.label}</label>
                            <select
                                value={formData.beneficios[beneficio.name]?.disponible || 0}
                                onChange={(e) =>
                                    setFormData((prevFormData) => ({
                                        ...prevFormData,
                                        beneficios: {
                                            ...prevFormData.beneficios,
                                            [beneficio.name]: {
                                                ...prevFormData.beneficios[beneficio.name],
                                                disponible: parseInt(e.target.value)
                                            }
                                        }
                                    }))
                                }
                                className="border rounded px-2 py-1 text-sm"
                            >
                                <option value={0}>No Disponible</option>
                                <option value={1}>Disponible</option>
                                <option value={2}>Parcialmente Disponible</option>
                            </select>
                        </div>
                    ))}
                </div>

                    {/* Pruebas */}
                    <h3 className="font-bold mb-2">Pruebas</h3>
                    <div className="grid grid-cols-2 gap-4 mb-4">
                        <div>
                            <label className="block text-gray-700 text-sm font-bold mb-2">Pruebas Prácticas</label>
                            <input
                                type="number"
                                name="pruebasPracticas.cantidadPreguntas"
                                value={formData.pruebasPracticas.cantidadPreguntas}
                                onChange={handleInputChange}
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                placeholder="Cantidad de preguntas"
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700 text-sm font-bold mb-2">Cantidad Pruebas Prácticas</label>
                            <input
                                type="number"
                                name="pruebasPracticas.cantidadPruebas"
                                value={formData.pruebasPracticas.cantidadPruebas}
                                onChange={handleInputChange}
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                placeholder="Cantidad de pruebas"
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700 text-sm font-bold mb-2">Pruebas Finales</label>
                            <input
                                type="number"
                                name="pruebasFinales.cantidadPreguntas"
                                value={formData.pruebasFinales.cantidadPreguntas}
                                onChange={handleInputChange}
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                placeholder="Cantidad de preguntas"
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700 text-sm font-bold mb-2">Cantidad Pruebas Finales</label>
                            <input
                                type="number"
                                name="pruebasFinales.cantidadPruebas"
                                value={formData.pruebasFinales.cantidadPruebas}
                                onChange={handleInputChange}
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                placeholder="Cantidad de pruebas"
                            />
                        </div>
                    </div>

                    {/* Total de Preguntas */}
                    <div className="grid grid-cols-2 gap-4 mb-4">
                        <div>
                            <label className="block text-gray-700 text-sm font-bold mb-2">Total de Preguntas</label>
                            <input
                                type="number"
                                name="totalPreguntas"
                                value={formData.totalPreguntas}
                                onChange={handleInputChange}
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                placeholder="Total de preguntas"
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700 text-sm font-bold mb-2">Descripción</label>
                            <input
                                type="text"
                                name="descripcion"
                                value={formData.descripcion}
                                onChange={handleInputChange}
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                placeholder="Introduce una descripción"
                            />
                        </div>
                    </div>

                    {/* Botones */}
                    <div className="flex justify-end mt-4">
                        <button
                            type="button"
                            onClick={closeModal}
                            className="bg-gray-500 text-white py-2 px-4 rounded mr-2"
                        >
                            Cancelar
                        </button>
                        <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded">
                            {plan ? 'Actualizar' : 'Crear'}
                        </button>
                    </div>
                </form>
            </div>
        </div>

    )
}
