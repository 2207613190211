import React, { useState } from 'react'
import { BenefitItem } from '../benefit-item/BenefitItem';
import { BiPencil } from 'react-icons/bi';
import { ModalUpdatePlan } from '../../update-plans-modal/ModalUpdatePlan';

export const PrecingCard = ({ plan, refresPlans }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
 
  return (
    <>
      <ModalUpdatePlan
        isOpen={isModalOpen}
        closeModal={closeModal}
        plan={plan.data}
        idPlan={plan.id}
        refresPlans={refresPlans}
      />
      <div className="flex flex-col justify-between bg-white rounded-lg shadow-lg overflow-hidden w-full max-w-sm">
        <div className="bg-red-600 p-4 text-white">
          <h2 className="text-xl font-bold">{plan.data.nombre}</h2>
          <p className="text-3xl font-bold mt-2">Bs. {plan.data.precio}</p>
        </div>
        <div className="p-4 text-gray-700 text-sm">
          <p className="mb-4">{plan.data.descripcion}</p>
          <p className="mb-2"><strong>Suscripción:</strong> {plan.data.suscripcion}</p>
          <ul className="mb-4 space-y-1">
            <li>Pruebas Prácticas: {plan.data.pruebasPracticas.cantidadPruebas} prueba/s - {plan.data.pruebasPracticas.cantidadPreguntas} preguntas</li>
            <li>Pruebas Finales: {plan.data.pruebasFinales.cantidadPruebas} prueba/s - {plan.data.pruebasFinales.cantidadPreguntas} preguntas</li>
            <li>Total de Preguntas: {plan.data.totalPreguntas}</li>
          </ul>
          <p className="font-bold mb-2">Beneficios:</p>
          <ul className="space-y-1">
            {Object.values(plan.data.beneficios).sort((a, b) => a.descripcion.localeCompare(b.descripcion))
            .map((benefit, index) => (
              <BenefitItem key={index} benefit={benefit} />
            ))}
          </ul>
        </div>
        <div className="p-4 bg-gray-700">
          <button className="w-full bg-red-600 text-white py-2 px-4 rounded hover:bg-red-700 transition duration-300 flex items-center justify-center" onClick={openModal}>
            <BiPencil />
            Editar
          </button>
        </div>
      </div>
    </>
  );
};

