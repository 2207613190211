import React,{useState} from 'react';
import { FaAngleRight } from 'react-icons/fa';
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { Link } from "react-router-dom";
import ReactGA from 'react-ga';


export default function Card(props){
          
    const visibility = React.useContext(VisibilityContext)

    const [data,setData] = useState(props.data.data);
    const btnAnalitycs = (nombre) => {
      ReactGA.event({
        category: 'button-home-pruebas',
        action: 'Click button:Ver Detalles:'+nombre
      });
    }

    return (
      <>
        {
          data ?
          <div className="p-2 w-1/3 min-w-1/3 sm:w-full md:w-1/3 ">
          <div className={`bg-fondo rounded mt-2 ${data.estado === 'activo' && 'border-4 border-primary' } `}>
            <div className="card h-9/10 text-white m-3 flex flex-col justify-around">
              <div className="">
                <div className ="text-white font-bold text-base uppercase text-center -mx-3">{data.nombre}</div>
                <hr className="color-primary"/>
                <p className="font-normal text-xs text-center -mx-3 my-3">{data.descripcion}</p>
              </div>
              <div className='flex flex-col items-start'>
                <div>
                  <span className="font-regular text-primary">Total de areas:</span> 
                  <span>  {data.materias.length}</span>
                </div>
                <div className="font-normal text-xs"> 
                {/* <span className="font-bold text-primary"> Colaboradores :</span>
                  <ul>
                    {data.colaboradores.map((colaborador,index)=>{
                      return <li key={index}>{colaborador}</li>
                    })}
                  </ul> */}
                  <span className="font-regular text-primary"> Area :</span>
                  <span className=""> {data.area}</span>
                  {/* <span className="font-regular text-primary">{data.estado}</span>
                  <span className="font-regular text-primary">{props.id}</span> */}
                </div>
                
              </div>
              <div className="flex justify-center">
                {
                  data.estado === 'activo' && 
                  <span className='flex flex-col justify-center items-center'>
                    <p className='text-red-500'>Convocatoria Vigente</p>
                    <Link 
                    onClick={()=>btnAnalitycs(data.nombre)}
                    className="text-white font-normal mx-auto py-2 px-4 rounded-full my-3 hover:text-white hover:bg-primary border-2 border-primary bg-primary" 
                    to={{
                      pathname: `/Details`,
                      data: {data: data,id:props.id},
                    }}
                    >
                      Ver Detalles
                    </Link>
                  </span>
                }
                {
                  data.estado === 'proximo' && 
                  <span className='flex flex-col justify-center items-center'>
                    <p className='text-red-500'>Proximamente</p>
                    <p className="text-white font-normal mx-auto py-2 px-4 rounded-full my-3 hover:text-white hover:bg-primary border-2 border-primary">
                      {data.fecha}</p>
                    
                  </span>
                }
                {
                  data.estado === 'pasado' && 
                  <span className='flex flex-col justify-center items-center'>
                    <p className='text-white'>Convocatoria Pasada</p>
                    <p className="text-fondo font-normal mx-auto py-2 px-4 rounded-full my-3 hover:text-primary hover:bg-primary border-2 border-primary bg-white">
                      Contactanos
                    </p>
                  </span>
                }
               
              </div>
            </div>
          </div>
          </div>
          :null
        }
      </>
      
        
    );
}