import React, { useEffect, useState } from "react";
import Header from "../components/headerPage";
import { ImPower } from "react-icons/im";
import Comments from "../components/comments";
import Footer from "../components/footer";
import background from "../../assets/images/precios.png"
import newPlan from "../../assets/images/newPlan.jpg"
import inversion from "../../assets/images/inversion.png"
import { useDispatch, useSelector } from 'react-redux';
import { addCart } from '../../redux/actions/cart';
import Modal from "../components/Modal";
import { Link } from "react-router-dom";
import { FiClock } from "react-icons/fi";
import { FiClipboard } from "react-icons/fi";
import { AiOutlineFileText } from "react-icons/ai";
import { CgCheck } from "react-icons/cg";
import { BiX } from "react-icons/bi";
import { BsFillTelephoneFill } from "react-icons/bs";
import { getDataPage } from "../../redux/actions/dataPage";

const Card = (props) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <Modal visible={visible} title={props.tipo} onClose={() => { setVisible(false) }}>
        <div className="mx-3 w-full flex flex-col items-center">
          <p className="text-base text-center">Gracias por seleccionar el plan {props.tipo}</p>
          <p className="text-base text-center">Ir a convocatoria para seleccionar un examen o simulacro </p>
          <div className="flex" >
            <button className="bg-transparent text-primary border-2 border-primary font-bold py-2 my-5 px-4 rounded-full" onClick={() => {
              setVisible(false)
            }}>Cerrar</button>
            <Link className="bg-primary text-white font-bold py-2 my-5 px-4 rounded-full ml-2" onClick={() => {
              setVisible(false)
            }
            } to="/convocatoria">Ir a convocatoria</Link>
          </div>
        </div>
      </Modal>
      <div className="bg-white rounded-lg shadow-lg h-full px-3 flex flex-col items-start justify-around mx-5 sm:mx-1 md:mx-5 border-red-400 border-4">

        <div className="bg-red-500 w-full p-4 -mt-16 sm:mt-0 md:-mt-16 rounded-b-lg rounded-t-md">
          <div className="flex flex-col items-center">
            {props.tipo === 'Plan Premium' && <p className="font-bold bg-white text-primary text-center text-xl m-2 p-1  -mt-12 sm:-mt-8  md:-mt-12 z-10 w-4/6 rounded border-2 border-red-500">Recomendado</p>}
            {/* <p className="text-2xl text-left text-accent font-bold">Plan</p>props.tipo */}
            <p className="text-4xl text-center text-white font-bold">{props.tipo}</p>
          </div>

          <h1 className="text-5xl my-1 font-bold my-5 text-gray-700 text-center">Bs.<span className="text-white"> {props.precio}</span></h1>
        </div>
        {/* <p className="font-regular">Por Usario / Meses</p> */}
        <div className="flex flex-col justify-center items-start mx-4 mt-4">
          <p className="font-bold text-primary text-left text-lg md:text-lg sm:text-base flex items-center mr-2"><FiClock className="mr-2" />Suscripción: </p>
          <p className="font-regular text-center text-lg sm:text-base md:text-lg ml-3">{props.suscripcion}</p>
        </div>
        <div className="flex flex-col justify-start items-start mx-4 mt-4">
          <p className="font-bold text-primary text-left text-lg sm:text-base md:text-lg flex items-start mr-2"><FiClipboard className="mr-2 text-primary" />Pruebas Prácticas: {props.cantidadPracticas}</p>
          <p className="font-regular text-left text-lg sm:text-base md:text-lg  ml-3">{props.practicas}</p>
        </div>
        <div className="flex flex-col justify-start items-start mx-4 mt-4">
          <p className="font-bold text-primary text-left md:text-lg  text-lg sm:text-base flex items-start mr-2"><FiClipboard className="mr-2 text-primary" />Pruebas finales: {props.cantidadfinales}</p>
          <p className="font-regular text-left text-lg sm:text-base md:text-lg ml-3">{props.finales}</p>
        </div>
        <div className="flex flex-col justify-start items-start mx-4 mt-4">
          <p className="font-bold text-primary text-left text-lg sm:text-base md:text-lg flex items-start mr-2"><AiOutlineFileText className="mr-2 text-primary" />Total de Preguntas: </p>
          <p className="font-regular text-left text-lg sm:text-base md:text-lg ml-3">{props.preguntas}</p>
        </div>
        <p className="font-bold text-primary text-center text-xl flex items-center mr-2 mt-4">Beneficios:</p>
        <ol>
          <li className="font-regular text-left text-lg flex items-start mr-2">
            <p className={`font-regular text-left text-lg sm:text-base md:text-lg flex items-start mr-2 mt-1 ${!props.uno ? 'text-gray-400' : 'text-black'}`}>{props.uno ? <CgCheck size={32} className="text-red-500" /> : <BiX />} Examen cronometrado. </p>
          </li>
          <li className="font-regular text-left text-lg flex items-start mr-2">
            <p className={`font-regular text-left text-lg sm:text-base md:text-lg flex items-start mr-2 mt-1 ${!props.dos ? 'text-gray-400' : 'text-black'}`}>{props.dos ? <CgCheck size={32} className="text-red-500" /> : <BiX />} Calificacion inmediata. </p>
          </li>
          <li className="font-regular text-left text-lg flex items-start mr-2">
            <p className={`font-regular text-left text-lg sm:text-base md:text-lg flex items-start mr-2 mt-1 ${!props.tres ? 'text-gray-400' : 'text-black'}`}>{props.tres ? <CgCheck size={32} className="text-red-500" /> : <BiX />} Revisión.</p>
          </li>
          <li className="font-regular text-left text-lg flex items-start mr-2">
            <p className={`font-regular text-left text-lg sm:text-base md:text-lg flex items-start mr-2 mt-1 ${!props.cuatro ? 'text-gray-400' : 'text-black'}`}>{props.cuatro ? <CgCheck size={32} className="text-red-500" /> : <BiX />} Gráficos comparativos.</p>
          </li>
          <li className="font-regular text-left text-lg flex items-center mr-2">
            <p className={`font-regular text-lwft text-lg sm:text-base md:text-lg flex items-left mr-2 mt-1 ${!props.cinco ? 'text-gray-400' : 'text-black'}`}>{props.cinco ? <CgCheck size={32} className="text-red-500" /> : <BiX />} Puede modificar la fecha de sus exámenes finales.</p>
          </li>
          <li className="font-regular text-left text-lg flex items-center mr-2">
            <p className={`font-regular text-lwft text-lg sm:text-base md:text-lg flex items-left mr-2 mt-1 ${!props.seis ? 'text-gray-400' : 'text-black bg-red-100 rounded'}`}>{props.seis ? <CgCheck size={32} className="text-red-500" /> : <BiX />} Puede modificar las materias de los exámenes.</p>
          </li>
          {/*
            <li className="font-regular text-left text-lg flex items-start mr-2">
              <p className={`font-regular text-left text-lg sm:text-base md:text-lg flex items-start mr-2 mt-1 ${!props.seis?'text-gray-400':'text-black'}`}>{props.seis?<CgCheck size={32} className="text-red-500"/>:<BiX/>} SESION EN VIVO.</p>
            </li>*/
          }
        </ol>

        <button onClick={() => { setVisible(!visible) }} className="bg-primary text-white font-bold py-2 my-5 px-4 rounded-full m-auto">Suscribirse</button>
      </div>
    </>
  );
};

const Pricing = () => {
  const store = useSelector(state => state.dataPage);
  const [data, setData] = useState();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDataPage());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setData(store);
    // console.log(store.cart.datos)
    console.log(store)
    //console.log(data.dataPage[0].precios)
  }, [store]);

  const bar = document.getElementById('bar');
  if (bar) {
    bar.style.display = 'block';
  }

  return (
    <>
      <Header titulo={"Nuestros Planes"} nombre={"Planes"} mensaje={"Adquiere ahora un Plan"} image={background} />
      <div className="w-full py-20 sm:py-8 md:py-20 px-24 sm:px-8 md:px-24 flex flex-row sm:flex-col md:flex-row sm:px-4 md:px-20">
        <div className="w-2/4 p-5 py-8 justify-center items-center sm:w-full md:w-2/4 text-left sm:text-center md:text-left">
          <p className="text-2xl text-primary font-bold">Nosotros somos expertos</p>
          <p className="text-3xl sm:text-xl md:text-3xl">Brindamos la mejor solución al cliente para su preparación.</p>
        </div >
        <div className="w-1/4 p-3 sm:w-full md:w-1/2 text-left sm:text-center md:text-left">
          <ImPower size={50} className="text-primary sm:mx-auto md:mx-0 mx-0" />
          <p className="text-lg font-bold my-5">Optimiza tu tiempo</p>
          <p className="text-xl sm:text-base md:text-xl">La plataforma Apriori permite que tengas mejor administración del tiempo al momento de rendir el examen.</p>
        </div>
        <div className="w-1/4 p-3 sm:w-full md:w-1/2 text-left sm:text-center md:text-left">
          <ImPower size={50} className="text-primary sm:mx-auto md:mx-0 mx-0" />
          <p className="text-lg font-bold my-5">Familiaridad con el examen</p>
          <p className="text-xl sm:text-base md:text-xl">Una prueba simulacro te permite familiarizarte con lo que te encontrarás en tu examen, generando así mayor confianza.</p>
        </div>
      </div>
      {/* <button className="bg-primary text-white font-bold py-2 my-5 px-4 rounded-full" onClick={()=>{
            dispatch(addCart({
                datos: "Plan 1",
                tipo: "Plan 1",
            })).then(() => {
                alert(data.datos);
            });
        }}>Comprar</button> */}
      <div className="w-full bg-gray-600 p-6 flex flex-col ">
        <h3 className="text-center text-white font-bold text-3xl sm:text-2xl md:text-3xl">Planes Disponibles unicamente para la convocatoria:</h3>
        <h3 className="text-center text-white font-normal text-2xl sm:text-xl md:text-2xl mt-2">{data ? data.dataPage[0]?.tituloBanner : null}</h3>
        <p className="text-white text-center mt-6">Contáctanos para consultar sobre otros planes para una convocatoria diferente :</p>
        <a href="https://api.whatsapp.com/send?phone=525568888888&text=Hola%20quisiera%20saber%20más%20sobre%20los%20planes%20de%20Apriori"
          target="_blank"
          rel="noopener noreferrer"
          className="bg-white text-fondo font-bold py-2 my-2 p-4 rounded-full mx-auto flex items-center justify-center hover:bg-primary hover:text-white transition-all duration-300 ease-in-out"
        >
          <BsFillTelephoneFill className="mr-2" />
          Contáctanos
        </a>
      </div>
      <div className="w-full p-6 py-16 flex flex-col border-t-8 border-b-8 border-primary"
        style={{ backgroundImage: `url(${newPlan})`, backgroundSize: 'cover', backgroundPosition: 'top' }}
      >
        <h4 className="text-center text-white font-bold text-3xl sm:text-2xl md:text-3xl my-2">Ahora tenemos un plan, pruebalo ahora:</h4>
        <div className="bg-white w-1/3 md:w-1/3 sm:w-5/6 p-4 my-4 rounded-b-lg rounded-t-md self-center border-4 border-red-500">
          <div className="flex flex-col items-center bg-fondo py-4 rounded">
            <p className="text-4xl md:text-4xl sm:text-2xl text-center text-white font-bold"> Plan Fast
            </p>
          </div>

          <h1 className="text-5xl md:text-5xl sm:text-4xl font-bold my-5 text-gray-700 text-center">Bs.<span className="text-primary"> 60</span></h1>


        </div>
        <details className="w-1/4 md:w-1/4 sm:w-3/4 self-center">
          <summary className="text-white text-center py-4 -mt-10 rounded-md font-bold text-xl md:text-xl sm:text-lg bg-red-500 self-center">Ver Informacion</summary>

          <div className="m-1 bg-fondo rounded absolute border-4 border-primary -ml-20 p-8 max-w-45 z-100 md:-ml-20 sm:-ml-8 md:max-w-45 sm:max-w-screen-lg mr-4 md:mr-0 sm:mr-4">
            <p className="text-center mt-4 md:mt-4 sm:mt-0 text-sm text-white">
              Este plan solo esta disponible el dia de la adquisición, en las cuales podras realizar las pruebas que desees, al finalizar el tiempo se cerrara la sesión y no podras realizar mas pruebas.

            </p>

            <div className="flex flex-col justify-center items-start mx-4 mt-4">


              <p className="font-bold text-primary text-left text-lg md:text-lg sm:text-sm flex items-center mr-2"><FiClock className="mr-2" />Suscripción: </p>
              <p className="font-regular text-white text-center text-lg sm:text-sm md:text-lg ml-3">Hasta las 23:59 del dia de suscripción </p>
            </div>
            <div className="flex flex-col justify-start items-start mx-4 mt-4">
              <p className="font-bold text-primary text-left text-lg sm:text-sm md:text-lg flex items-start mr-2"><FiClipboard className="mr-2 text-primary" />Pruebas Prácticas: 3</p>
              <p className="font-regular text-left text-lg sm:text-sm md:text-lg  text-white ml-3">Más de 25 en cada examen</p>
            </div>
            <div className="flex flex-col justify-start items-start mx-4 mt-4">
              <p className="font-bold text-primary text-left md:text-lg  text-lg sm:text-sm flex items-start mr-2"><FiClipboard className="mr-2 text-primary" />Pruebas finales: 1</p>
              <p className="font-regular text-left text-lg sm:text-sm md:text-lg ml-3 text-white">Más de 40 en el examen</p>
            </div>
            <div className="flex flex-col justify-start items-start mx-4 mt-4">
              <p className="font-bold text-primary text-left text-lg sm:text-sm md:text-lg flex items-start mr-2"><AiOutlineFileText className="mr-2 text-primary" />Total de Preguntas: </p>
              <p className="font-regular text-left text-lg sm:text-sm md:text-lg ml-3  text-white">Más de 290 preguntas</p>
            </div>
            <p className="font-bold text-primary text-center text-xl md:text-xl sm:text-lg flex items-center mr-2 mt-4">Beneficios:</p>
            <ol>
              <li className="font-regular text-left text-lg flex items-start mr-2">
                <p className={`font-regular text-left text-lg sm:text-sm md:text-lg flex items-start mr-2 mt-1 text-white`}><CgCheck size={32} className="text-red-500" /> Examen cronometrado. </p>
              </li>
              <li className="font-regular text-left text-lg flex items-start mr-2">
                <p className={`font-regular text-left text-lg sm:text-sm md:text-lg flex items-start mr-2 mt-1 text-white`}><CgCheck size={32} className="text-red-500" /> Calificacion inmediata. </p>
              </li>
              <li className="font-regular text-left text-lg flex items-start mr-2">
                <p className={`font-regular text-left text-lg sm:text-sm md:text-lg flex items-start mr-2 mt-1 text-white`}><CgCheck size={32} className="text-red-500" />Revisión.</p>
              </li>
              <li className="font-regular text-left text-lg flex items-start mr-2">
                <p className={`font-regular text-left text-lg sm:text-sm md:text-lg flex items-start mr-2 mt-1 text-white`}><CgCheck size={32} className="text-red-500" />Gráficos comparativos.</p>
              </li>
              {/*
            <li className="font-regular text-left text-lg flex items-start mr-2">
              <p className={`font-regular text-left text-lg sm:text-base md:text-lg flex items-start mr-2 mt-1 ${!props.seis?'text-gray-400':'text-black'}`}>{props.seis?<CgCheck size={32} className="text-red-500"/>:<BiX/>} SESION EN VIVO.</p>
            </li>*/
              }
            </ol>
            {/* <button onClick={()=>{
            window.location.href = 'https://api.whatsapp.com/send?phone=59161885865&text=Hola,%20quiero%20mas%20informacion%20sobre%20el%20plan%20Fast%20de%20la%20plataforma'
          }} className="bg-primary text-white font-bold py-2 my-5 px-4 rounded-full m-auto">Contactar</button> */}
          </div>
        </details>


      </div>
      <div className="grid grid-cols-3 gap-4 w-full sm:grid-cols-1 md:grid-cols-3 py-20 px-10 pt-28 bg-gray-700" >

        <Card
          tipo={"Plan Básico"}
          precio={'120'}
          uno={true}
          dos={true}
          tres={true}
          cuatro={true}
          suscripcion='Hasta el día del examen'
          cantidadPracticas='3'
          cantidadfinales='1'
          practicas='Más de 50 preguntas en cada examen'
          finales='Más de 40 preguntas en cada prueba'
          preguntas='Más de 190 preguntas'
        />

        <Card
          tipo={"Plan Profesional"}
          precio={'210'}
          uno={true}
          dos={true}
          tres={true}
          cuatro={true}
          cinco={true}
          cantidadPracticas='5'
          cantidadfinales='2'
          suscripcion='Hasta el día del examen'
          practicas='Más de 50 preguntas en cada examen'
          finales='Más de 40 preguntas en cada prueba'
          preguntas='Más de 330 preguntas'
        />

        <Card
          tipo={"Plan Premium"}
          precio={'290'}
          uno={true}
          dos={true}
          tres={true}
          cuatro={true}
          cinco={true}
          seis={true}
          cantidadPracticas='8'
          cantidadfinales='2'
          suscripcion='Hasta el día del examen'
          practicas='Más de 50 preguntas en cada examen'
          finales='Más de 40 preguntas en cada prueba'
          preguntas='Más de 480 preguntas'
        />

      </div>
      <Footer />

    </>
  );
}

export default Pricing;