import { doc, updateDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { db } from '../../../../config/firebase';
import { getAllMaterias } from '../../../counts/utils';

export const ModalUpdateExam = ({ isOpen, closeModal, examen, idExamen, refresExams }) => {
    const [formData, setFormData] = useState({
        nombre: examen ? examen.data.nombre : '',
        area: examen ? examen.data.area : '',
        descripcion: examen ? examen.data.descripcion : '',
        materias: examen ? examen.data.materias : [],
        documentacion: examen ? examen.data.documentacion : [],
        recursos: examen ? examen.data.recursos : [],
        estado: examen ? examen.data.estado : '',
        fecha: examen ? examen.data.fecha : '',
        visible: examen ? examen.data.visible : false,
    });

    const [selectedMateria, setSelectedMateria] = useState('');
    const [documentacionInput, setDocumentacionInput] = useState('');
    const [recursoInput, setRecursoInput] = useState({ nombre: '', url: '' });
    const [materias, SetMaterias] = useState([]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const dataMaterias = await getAllMaterias();
                const materias = dataMaterias.map(materia => ({
                    id: materia.id,
                    nombre: materia.nombre
                }));
                SetMaterias(materias)
            } catch (error) {
                console.error('Error fetching materias: ', error);
            }
        };

        fetchData();
    }, []);

    const handleSelectMaterias = (e) => {
        const selectedId = e.target.value;
        const selectedMateria = materias.find(materia => materia.id === selectedId);
        if (selectedMateria) {
            const materiaConIdMayuscula = {
                ...selectedMateria,
                Id: selectedMateria.id,
            };

            if (!formData.materias.some(materia => materia.Id === materiaConIdMayuscula.Id)) {
                setFormData({
                    ...formData,
                    materias: [...formData.materias, materiaConIdMayuscula],
                });
            } else {
                alert('Materia ya agregada.');
            }

            setSelectedMateria('');
        }
    };

    const agregarDocumentacion = () => {
        if (documentacionInput) {
            setFormData({
                ...formData,
                documentacion: [...formData.documentacion, documentacionInput],
            });
            setDocumentacionInput(''); // Limpiar el input
        }
    };

    const agregarRecurso = () => {
        if (recursoInput.nombre && recursoInput.url) {
            setFormData({
                ...formData,
                recursos: [...formData.recursos, recursoInput],
            });
            setRecursoInput({ nombre: '', url: '' }); // Limpiar los inputs
        }
    };

    const handleRecursoChange = (e) => {
        const { name, value } = e.target;
        setRecursoInput({
            ...recursoInput,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const examId = idExamen

        try {

            const selectedDate = new Date(formData.fecha);
            const formattedDate = selectedDate.toLocaleString('en-US', {
                month: 'numeric',
                day: 'numeric',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
            });

            const docRef = doc(db, 'Exam', examId);
            await updateDoc(docRef, {
                ...formData,
                fecha: formattedDate

            });
            alert('Documento actualizado con éxito con ID: ' + examId);
            refresExams();
        } catch (error) {
            alert("Error al actualizar los datos: " + error);
            console.log(error);
        }
        closeModal();
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-6 rounded-lg w-11/12 max-w-full h-9/10 overflow-y-auto">
                <h2 className="text-xl font-bold mb-4">
                    {examen ? 'Editar Examen' : 'Agregar Examen'}
                </h2>
                <form onSubmit={handleSubmit} className="h-full">
                    {/* Campos Generales */}
                    <div className="grid grid-cols-2 gap-4 mb-4">
                        <div>
                            <label className="block text-gray-700 text-sm font-bold mb-2">Nombre</label>
                            <input
                                type="text"
                                name="nombre"
                                value={formData.nombre}
                                onChange={handleInputChange}
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                placeholder="Introduce el nombre"
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700 text-sm font-bold mb-2">Área</label>
                            <input
                                type="text"
                                name="area"
                                value={formData.area}
                                onChange={handleInputChange}
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                placeholder="Introduce el área"
                            />
                        </div>
                        <div className="col-span-2">
                            <label className="block text-gray-700 text-sm font-bold mb-2">Descripción</label>
                            <input
                                type="text"
                                name="descripcion"
                                value={formData.descripcion}
                                onChange={handleInputChange}
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                placeholder="Introduce la descripción"
                            />
                        </div>
                    </div>

                    {/* Materias */}
                    <div className="flex mb-4">
                        <div className="flex-1">
                            <h3 className="font-bold">Materias</h3>
                            <select
                                value={selectedMateria}
                                onChange={handleSelectMaterias}
                                className="mb-2 border rounded w-full py-2 px-3"
                            >
                                <option value="">Selecciona una materia</option>
                                {materias.map(materia => (
                                    <option key={materia.id} value={materia.id}>
                                        {materia.nombre}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="ml-4" style={{ width: '70%', maxHeight: '150px', overflowY: 'auto' }}>
                            <table className="min-w-full border">
                                <thead>
                                    <tr>
                                        <th className="border px-2 py-1">Materias Agregadas</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {formData.materias.map((materia, index) => (
                                        <tr key={index}>
                                            <td className="border px-2 py-1">{materia.Id} - {materia.nombre}</td>
                                            <td className="border px-2 py-1">
                                                <button
                                                    type="button"
                                                    onClick={() => {
                                                        const nuevasMaterias = formData.materias.filter((_, i) => i !== index);
                                                        setFormData({ ...formData, materias: nuevasMaterias });
                                                    }}
                                                    className="text-red-500"
                                                >
                                                    Eliminar
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    {/* Documentación */}
                    <div className="mb-4 flex">
                        <div className="flex-1">
                            <h3 className="font-bold">Documentación</h3>
                            <input
                                type="text"
                                value={documentacionInput}
                                onChange={(e) => setDocumentacionInput(e.target.value)}
                                className="border rounded w-full py-2 px-3 mb-2"
                                placeholder="Introduce un enlace de documentación"
                            />
                            <button
                                type="button"
                                onClick={agregarDocumentacion}
                                className="text-blue-500 hover:underline mb-2"
                            >
                                Agregar Documentación
                            </button>

                        </div>
                        <div className="ml-4" style={{ width: '70%', maxHeight: '150px', overflowY: 'auto' }}>
                            <table className="min-w-full border">
                                <thead>
                                    <tr>
                                        <th className="border px-2 py-1">Documentación Agregada</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {formData.documentacion.map((doc, index) => (
                                        <tr key={index}>
                                            <td className="border px-2 py-1">{doc}</td>
                                            <td className="border px-2 py-1">
                                                <button
                                                    type="button"
                                                    onClick={() => {
                                                        const nuevaDocumentacion = formData.documentacion.filter((_, i) => i !== index);
                                                        setFormData({ ...formData, documentacion: nuevaDocumentacion });
                                                    }}
                                                    className="text-red-500"
                                                >
                                                    Eliminar
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    {/* Recursos */}
                    <div className="mb-4 flex">
                        <div className="flex-1">
                            <h3 className="font-bold">Recursos</h3>
                            <input
                                type="text"
                                name="nombre"
                                value={recursoInput.nombre}
                                onChange={handleRecursoChange}
                                className="border rounded w-full py-2 px-3 mb-2"
                                placeholder="Nombre del recurso"
                            />
                            <input
                                type="text"
                                name="url"
                                value={recursoInput.url}
                                onChange={handleRecursoChange}
                                className="border rounded w-full py-2 px-3 mb-2"
                                placeholder="URL del recurso"
                            />
                            <button
                                type="button"
                                onClick={agregarRecurso}
                                className="text-blue-500 hover:underline mb-2"
                            >
                                Agregar Recurso
                            </button>
                        </div>
                        <div className="ml-4" style={{ width: '70%', maxHeight: '150px', overflowY: 'auto' }}>
                            <table className="min-w-full border">
                                <thead>
                                    <tr>
                                        <th className="border px-2 py-1">Nombre</th>
                                        <th className="border px-2 py-1">Url</th>
                                        <th className="border px-2 py-1"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {formData.recursos.map((recurso, index) => (
                                        <tr key={index}>
                                            <td className="border px-2 py-1">{recurso.nombre}</td>
                                            <td className="border px-2 py-1">{recurso.url}</td>
                                            <td className="border px-2 py-1">
                                                <button
                                                    type="button"
                                                    onClick={() => {
                                                        const nuevosRecursos = formData.recursos.filter((_, i) => i !== index);
                                                        setFormData({ ...formData, recursos: nuevosRecursos });
                                                    }}
                                                    className="text-red-500"
                                                >
                                                    Eliminar
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    {/* Estado y Fecha */}
                    <div className="grid grid-cols-2 gap-4 mb-4">
                        <div>
                            <label className="block text-gray-700 text-sm font-bold mb-2">Estado</label>
                            <input
                                type="text"
                                name="estado"
                                value={formData.estado}
                                onChange={handleInputChange}
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                placeholder="Introduce el estado"
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700 text-sm font-bold mb-2">Fecha</label>
                            <input
                                type="datetime-local"
                                name="fecha"
                                value={formData.fecha}
                                onChange={handleInputChange}
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            />
                        </div>
                    </div>

                    {/* Checkbox para visible */}
                    <div className="flex items-center mb-4">
                        <input
                            type="checkbox"
                            name="visible"
                            checked={formData.visible}
                            onChange={() => setFormData({ ...formData, visible: !formData.visible })}
                            className="mr-2"
                        />
                        <label className="text-gray-700 text-sm">Visible</label>
                    </div>

                    <div className="flex justify-end mt-4">
                        <button
                            type="button"
                            onClick={closeModal}
                            className="bg-gray-500 text-white py-2 px-4 rounded mr-2"
                        >
                            Cancelar
                        </button>
                        <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded">
                            {examen ? 'Actualizar' : 'Crear'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}
