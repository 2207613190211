import React,{useRef} from 'react';
import { FaFacebookF, FaInstagram,FaEnvelope, FaWhatsapp} from 'react-icons/fa';


export default function NavBarMenu(props) {
    //const [offset, setOffset] = useState(0);

    const navbar = useRef(null);

    //const [toggleMenu, setToggleMenu] = useState(false);

    /*const handleToggle = () =>{
      setToggleMenu(!toggleMenu)
      {offset>25?" fixed w-full px-40 sm:px-5 lg:px-40 py-4 text-white flex  justify-between bg-fondo text-14"}
      <div className={ offset>50?"fixed w-full px-40 sm:px-5 lg:px-40 py-4 text-red-500 flex flex-col justify-between bg-fondo text-14 -mt-14 md:-mt-26 lg:-mt-14 sm:-mt-26"
          :" fixed w-full px-40 sm:px-5 lg:px-40 py-4 text-white flex  justify-between bg-fondo text-14"}>
          :"fixed w-full px-40 sm:px-5 lg:px-40 py-4 text-white flex  justify-between bg-fondo text-14 top-0"
       
    }*/

  return (
    <>
      <div ref={navbar} className="grid sm:hidden md:grid  bg-fondo grid-cols-1 md:grid-cols-1 lg:grid-cols-2 items-center py-2 pb-3 border-b border-gray-500 text-base z-40">
        <div className="flex row justify-center inset-x-1.5 md:justify-center lg:justify-start p-3 my-0 md:py-2 sm:py-2  pt-5 text- md:pl-0 text-1xl lg:pl-32">
          <a
            href="https://www.facebook.com/aprioribol"
            target="_blank"
            rel="noopener noreferrer"
          >< FaFacebookF  className="text-white "/></a>
          <a href="https://www.instagram.com/apriori_bo"
            target="_blank"
            rel="noopener noreferrer"
          >< FaInstagram  className="text-white mx-3"/></a>
          <a 
            href="https://wa.me/+59161885865"
            target="_blank"
            rel="noopener noreferrer"
          >
            < FaWhatsapp  className="text-white"/></a>
        </div>
        <div className="flex flex-row md:flex-col  sm:flex-col lg:flex-row text-white items-center base">
            <a className="text-textBajo base"  href="tel:+591-61885865">Contactos : <span className="text-white">+591 61885865</span></a>
            <a className="flex flex-row px-10 base" href="mailto:apriori.bolivian@gmail.com" >< FaEnvelope  className="text-textBajo mt-1 mr-2"/><p>apriori.bolivian@gmail.com</p></a>
            <a className="flex flex-row px-10 base" href="admin" ><p>admin</p></a>
        </div>
      </div>
    </>
  )
}