const isInDevelopment = true;
module.exports = {
    user :isInDevelopment ? '_user' : 'user',
    exam :isInDevelopment ? 'Exam' : 'Exam',
    suggestios :isInDevelopment ? 'Suggestions_' : 'Suggestions',
    cursos : isInDevelopment? 'Cursos' : 'Cursos',
    materias : isInDevelopment? 'Materias' : 'Materias',
    galeria : isInDevelopment? 'galeria' : 'galeria',
    planes : isInDevelopment? 'planes' : 'planes',
};

