import Home from './home';
import Login from  './login';
import Privada from './privada';
import Signup from './signup';
import Team from './team/index';
import Service from './service';
import Contact from './contact';
import Pricing from './pricing';
import Profile from './profile';
import NotFound from './notFound';
import Examen from './Examen';
import ExamTest from './ExamTest';
import Details from './details';
import Convocatoria from './convocatoria';
import Plans from './plans';
import Suggestions from './suggestions';
import Management from './management';
import Events from './events';
import RegisterToEvent from './registerToEvent';
import Live from './live';
import Counts from './counts';
import Payment from './Payment';
import Driver from './driver';
import UserProfile from './userProfile';
import Licencias from './licencias';
import Print from './print';
import AdminAfiliate from './afiliateAdmi';
import Copia from './Examen/copia';
import Admin from './admin';
import Teacher from './teacher';
import SimplePay from './SimplePay';

const exports = {
    Home,
    Login,
    Privada,
    Signup,
    Team,
    Service,
    Contact,
    Pricing,
    Profile,
    NotFound,
    Examen,
    Details,
    Convocatoria,
    Plans,
    Suggestions,
    ExamTest,
    Management,
    Events,
    RegisterToEvent,
    Live,
    Counts,
    Payment,
    Driver,
    UserProfile,
    Licencias,
    Print,
    AdminAfiliate,
    Copia,
    Admin,
    Teacher,
    SimplePay
}

export default exports;
