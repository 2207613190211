import React, { useState } from 'react'
import { db, storage } from '../../../../config/firebase';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { addDoc, collection } from 'firebase/firestore';

export const ModalAddGalery = ({ isOpen, closeModal, refresImages }) => {
    const [title, setTitle] = useState('');
    const [photo, setPhoto] = useState(null);
    const [isUploading, setIsUploading] = useState(false);

    if (!isOpen) return null;

    const handlePhotoChange = (e) => {
        setPhoto(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!photo) {
            alert("Por favor selecciona una fotografía.");
            return;
        }

        setIsUploading(true);

        try {
            // Subir la imagen a Firebase Storage
            const photoRef = ref(storage, `galeria/${photo.name}`);
            await uploadBytes(photoRef, photo);

            // Obtener la URL de la imagen cargada
            const photoURL = await getDownloadURL(photoRef);

            // Guardar el título y la URL de la imagen en Firestore
            await addDoc(collection(db, 'galeria'), {
                titulo: title,
                url: photoURL
            });

            alert("Fotografía agregada correctamente.");
            closeModal();

            // Reinicia el formulario
            setTitle('');
            setPhoto(null);
            refresImages();
        } catch (error) {
            console.error("Error al guardar la fotografía:", error);
            alert("Error al guardar la fotografía.");
        } finally {
            setIsUploading(false);
        }
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-6 rounded-lg w-2/4 max-w-full h-2/4 ">
                <p className="text-xl font-bold mb-4">Agregar Fotografía</p>
                <form className="h-9/10 overflow-y-auto" onSubmit={handleSubmit}>
                    {/* Campo para el Título */}
                    <div className="mb-4">
                        <label htmlFor="title" className="block text-gray-700 font-semibold mb-2">Título de la Fotografía</label>
                        <input
                            type="text"
                            id="title"
                            name="title"
                            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
                            placeholder="Escribe un título"
                            required
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                    </div>

                    {/* Campo para la Fotografía */}
                    <div className="mb-4">
                        <label htmlFor="photo" className="block text-gray-700 font-semibold mb-2">Selecciona una Fotografía</label>
                        <input
                            type="file"
                            id="photo"
                            name="photo"
                            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
                            accept="image/*"
                            onChange={handlePhotoChange}
                            required
                        />
                    </div>

                    {/* Botones de Cancelar y Guardar */}
                    <div className="flex justify-end">
                        <button
                            type="button"
                            className="mr-2 bg-gray-400 text-white py-2 px-4 rounded"
                            onClick={closeModal}
                        >
                            Cancelar
                        </button>
                        <button
                            type="submit"
                            className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
                            disabled={isUploading}
                        >
                            {isUploading ? "Guardando..." : "Guardar"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};