/* eslint-disable no-lone-blocks */
import React,{useEffect, useState} from "react";
import {Link, Redirect } from 'react-router-dom';
import Modal from "../../components/Modal";
import { FaWhatsapp } from "react-icons/fa";
import  {Contactanos} from '../../components/uiComponents';
import {useSelector} from 'react-redux';

export default function ExamenCard({plan,idCuenta,data}){
      // 
    const [showModal, setShowModal] = useState(false);
    const [showModalInfo, setShowModalInfo] = useState(false);
    const [idCuenta_, setIdCuenta_] = useState();
    const {cuenta} = useSelector(state => state.user);

    const [habilitado, setHabilitado] = useState(false);
    const [mensaje, setMensaje] = useState("no hay mensaje");

    const [idExamen, setIdExamen] = useState("");



    const btnAbrir = (d)=>{
      
      if(d.plan.includes("ast")){
        let ahora = new Date();
        let fechaExamen = new Date(d.fechaRegistro);
        
        if(ahora.getDay() === fechaExamen.getDay()){
          if(d?.intentos > 0){
            setShowModal(true)
          } else {
            //alert("No tiene intentos disponibles");
            window.confirm("No tiene intentos disponibles");
          }
        }else{
          window.confirm("Su suscripción no esta disponible o ya expiro");
        }
      }else{
        if(d?.intentos > 0){
          setShowModal(true)
        } else {
          //alert("No tiene intentos disponibles");
          window.confirm("No tiene intentos disponibles");
        }
      }
    }

    const changeState = (state, menssage) => { 
      setHabilitado(state);
      setMensaje(menssage);
    }
    const loadIdExamen = (dataUs) => {
      let idExamenDato = "";
      dataUs?.examenes?.forEach(element => {
        if(element.isResolve === false){
          idExamenDato = element.id;
        }
      });

      setIdExamen(idExamenDato) ;
    }
    useEffect(() => {
      if(data){
        console.log("data",data);
        loadIdExamen(data);
      }
    }, [data])
      

    useEffect(() => {
      if(data.tipo ==="PFinal"){
        cuenta?.forEach(element => {
          if(element.nombre === data.nombre){
            if(element.tipo === "PPrac"){
              
              if(plan.includes("ast")){
                let fechaActual = new Date();
                let fechaExamen = new Date(element.fechaRegistro);

                if (fechaActual.getDay() === fechaExamen.getDay() ){
                  if(element.intentos+'' === "0"){
                    changeState(true, "Mucho exito en su examen.");
                  } else {
                    changeState(false, "Disponible al terminar sus intentos de practica.");
                  }
                }
                else{
                  changeState(false, "Suscripción no disponible.");
                }
              }


              if(plan.includes("sico")){
                if(element.intentos+'' === "0"){
                  changeState(true, "Mucho exito en su examen.");
                } else {
                  changeState(false, "Disponible al terminar sus intentos de practica.");
                }
              }

              if(plan.includes("rofe")){
                switch (data.intentos+'') {
                  case "0": {
                    changeState(false, "Disponible al terminar sus intentos de practica.");
                  }break;
                  case "1": {
                    if(parseInt(element.intentos) === 0){
                      changeState(true, "Mucho exito en su examen.");
                    }else{
                      changeState(false, "Disponible al terminar los intentos de practica.");
                    }
                    
                  } break;
                  case "2": {
                    if(parseInt(element.intentos) <= 3){
                      changeState(true, "Mucho exito en su examen.");
                    }else{
                      changeState(false, `Disponible despues de 2 intentos de practica.${data.intentos}`);
                    }
                  }break;
                  default:{
                    changeState(false, `Disponible al terminar los intentos de practicaa. ${data.intentos}`);
                  }
              }
            }

            if(plan.includes("mium")){
              switch (data.intentos+'') {
                case "0": {
                  changeState(false, "Disponible al terminar sus intentos de practica.");
                }break;
                case "1": {
                  if(parseInt(element.intentos) === 0){
                    changeState(true, "Mucho exito en su examen.");
                  }else{
                    changeState(false, "Disponible al terminar los intentos de practica.");
                  }
                  
                } break;
                case "2": {
                  if(parseInt(element.intentos) <= 4){
                    changeState(true, "Mucho exito en su examen.");
                  }else{
                    changeState(false, "Disponible despues de 4 intentos de practica.");
                  }
                }break;
                default:{
                  changeState(false, "Disponible al terminar los intentos de practica.");
                }
            }
          }
          }
        }
        });
      } else {

      }
    }, [cuenta])


    useEffect(() => {
      if(idCuenta){
        setIdCuenta_(idCuenta);
      } else {
        console.log("No hay idCuenta");
      }
    }, [idCuenta])
    return (
      <>
        <Modal visible={showModalInfo} title="Apriori informa." onClose={()=>setShowModalInfo(false)}>
          <div className=" p-6 flex flex-col justify-center items-start">
            <p>Estamos trabajandando para que el pago de nuestro servicio sea verificado</p>
            <p>este proceso puede durar como maximo 24 horas.</p>
            <p>
              Si no recibes una respuesta en 24 horas, por favor comunicate con nosotros.
            </p>
            <div className="flex w-full justify-center mt-5">
              <button
                className="flex flex-row items-center hover:bg-green-400 hover:border-green-400 bg-transparent border-2 border-primary hover:text-white text-primary font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none mb-1"
                type="button"
                style={{ transition: "all .15s ease" }}
              >
                <FaWhatsapp size={24} className='mr-4'/>
                <a
                  href="https://wa.me/56975555555"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  
                  Contactanos
                </a>
              </button>
            </div>      
          </div>
          {/*footer*/}
          <div className="flex items-center justify-end border-t border-solid border-blueGray-200 rounded-b">
            <button
              className="text-white hover:text-primary bg-primary mt-2 rounded font-bold uppercase px-6 py-2 text-sm outline-none hover:bg-transparent border-2 border-primary mr-1ease-linear transition-all duration-150"
              type="button"
              onClick={() => setShowModalInfo(false)}
            >
              
            Aceptar
            </button>
          </div>
        </Modal>
       {showModal ? (
          <>
            <div
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
              <div className="relative w-auto my-6 mx-auto max-w-3xl">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t bg-gray-100">
                    <h3 className="text-3xl font-semibold text-primary">
                      {data.nombre}
                    </h3>
                    <button
                      className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => setShowModal(false)}
                    >
                      <span className="bg-transparent text-primary h-6 w-6 text-2xl block outline-none focus:outline-none">
                        ×
                      </span>
                    </button>
                  </div>
                  {/*body*/}
                  <div className=" p-6 flex flex-row justify-center items-center">
                    <div className="w-1/2 mx-6 text-blueGray-500 self-start ">
                    <h3 className="font-bold text-base mb-2 text-primary">Recomendaciones antes de comenzar</h3><hr/>
                      <ol className="ml-8">
                        <li type="disc">Asegurese de tener una buena conexion a internet.</li>
                        <li type="disc">Revise muy bien los intentos disponibles.</li>
                        <li type="disc">Para una mejor experiencia inicie en una computadora.</li>
                        <li type="disc">Controle muy bien su tiempo disponible.</li>
                        <li type="disc">Sitúese en un lugar como y libre de distracciones.</li>
                      </ol>

                      <Link to={`/`} className="text-blue-800 text-center mt-4">Terminos y Condiciones</Link>
                        
                    </div>
                    <div className="w-1/2" >
                      <h3 className="font-bold mb-2 text-primary text-base">Preguntas del examen</h3><hr/>
                        <div className="flex flex-row items-start justify-between py-3 px-6">
                          <ol>
                          {
                            data.materias.map((pregunta,index) => {
                              return (
                                <li key={index} type='disc' className="text-blueGray-500 my-2">{pregunta}</li>
                              )
                            })
                          }
                          </ol>
                          {/* <div>as</div> */}
                        </div>        
                    </div>
                    
                  </div>
                  <p className="mx-16 text-primary font-700 text-center my-4">Este examen tiempo un tiempo limite, Le Recomendamos controlar muy bien su tiempo disponible...</p>
                  {/*footer*/}
                  <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b bg-gray-300">
                    <button
                      className="text-gray-800 hover:text-primary background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => setShowModal(false)}
                    >
                      Cancelar
                    </button>
                    {
                        idExamen&&
                        <Link 
                          className="text-center md:inline-block cursor-pointer hover:bg-green-500 md:border-none py-2 px-3 bg-primary rounded text-white" to={`/examen/${idExamen}`}
                        >Continuar</Link>
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      <div className=" flex flex-col mt-3 m-3 justify-between items-center text-black border-accent border-2 w-full rounded-md  bg-basic max-w-45 md:max-w-45 sm:max-w-full">
        <div className="bg-accent text-white w-full text-center py-2 px-4">
          <h1 className="border-b-2 border-white" >{data.nombre}</h1>
          <h3>{data.tipo==='PFinal'? 'Prueba Final': 'Pruebra de Práctica'}</h3>
        </div>
        <div className="flex flex-col justify-start items-start  w-full p-3"> 
          <p className="text-start text-primary">Fecha de examen: <span className="text-black">{data.fechaExamen}</span> </p>
          {//<p className="text-start text-primary">Materias: <span className="text-black"></span> </p>
          }
          {/*<ol className="pl-5">
            {
              data.materias.map((materia,index)=>{
                return <li type="disc" key={index}>{materia}</li>
              })
            }
          </ol>*/}
          <p className="text-start text-primary">Intentos disponibles: <span className="text-black pl-1">{data.intentos}</span> </p>
          <p className="text-start text-primary">Total de preguntas: <span className="text-black pl-1">{data.cantidadPreguntas}</span> </p>
          <p>{data.estado}</p>

          {/**<Link className="bg-primary text-white font-normal mx-auto my-2 py-2 px-4 rounded my-3" to={{
          pathname: `/Details`,
          data: props.data,
        }}>Ver Detalles</Link> */}
          <p className="text-start text-primary">Plan: <span className="text-black pl-1">{plan}</span> </p>
        </div>
        {
          data.type !== 'teacher' ?
          <div className="p-4">
          {
              data.estado === 'activo' ?
                data.tipo === 'PFinal' ?
                  habilitado ? 
                  <button className="bg-primary text-white py-2 px-4 rounded-md" onClick={() =>{btnAbrir(data) } }>
                      {data.estado=== 'pendiente' ? 'Plan Pendiente' : 'Abrir Examen'}
                  </button>
                  :
                  <button className="bg-gray-700 text-white py-2 px-4 rounded-md" onClick={() =>{alert('No disponible')}}>
                    {data.estado=== 'pendiente' ? 'Plan Pendiente' : 'No disponible'}
                  </button>
                  // data.fechaExamen === new Date().toLocaleDateString() ?
                  //   <button className="bg-primary text-white py-2 px-4 rounded-md" onClick={() =>{btnAbrir(data) } }>
                  //     {data.estado=== 'pendiente' ? 'Plan Pendiente' : 'Abrir Examen'}
                  //   </button>
                  //   :
                  //   <button className="bg-gray-700 text-white py-2 px-4 rounded-md" onClick={() =>{alert('No disponible')}}>
                  //     {data.estado=== 'pendiente' ? 'Plan Pendiente' : 'Disponible el  '+data.fechaExamen}
                  //   </button>
                :
                <button className="bg-primary text-white py-2 px-4 rounded-md" onClick={() =>{btnAbrir(data) } }>
                    {data.estado=== 'pendiente' ? 'Plan Pendiente' : 'Abrir Examen'}
                </button>
              :
              data.estado === 'pendiente' ?
                <div className="flex flex-row justify-center items-center">
                {
                  data.type !== 'teacher' &&
                  <Link className="bg-gray-500 text-center text-white py-2 px-4 rounded-md mr-2" to={{
                    pathname: `/plans`,
                    data: data,
                  }}>
                    {
                      data.plan === 0 || data.plan === 'Pendiente'?
                      'Seleccionar Plan' :
                      'Cambiar Plan'
                    }
                  </Link>
                }
                <Link className="bg-gray-500 text-center text-white py-2 px-4 rounded-md ml-2" to={{
                  pathname: `/payment`,
                  data: data,
                }}>
                  Reportar Pago
                </Link>
                </div>
                :
              data.estado === 'aprobacion' ?
              <button className="bg-green-600 text-white py-2 px-4 rounded-md" onClick={() =>{setShowModalInfo(true)} }>
                Pago en aprobación
              </button>
                :<p>Algo salio mal </p>
            }
          </div>
          :
          <div className="p-4">
            {
              data.estado === 'activo' ?
              <button className="bg-primary text-white py-2 px-4 rounded-md" onClick={() =>{setShowModal(true)} }>
                Iniciar Examen
              </button>
              :
              <Link className="bg-gray-500 text-white py-2 px-4 rounded-md" to={{
                  pathname: `/simple-pay`,
                  data: data,
              }}>
                Reportar Pago
              </Link>
            }
          </div>
        }
        {data.tipo==='PFinal'&&<p className="text-sm">{mensaje}</p>}
        <p className="text-center text-primary">aaa{idExamen}</p>
        
        <Contactanos type='text' texto='Solicitar una Prueba' mensaje={'Apriori, Me gustaría solicitar mi primera prueba en la plataforma'} style={{
              marginY:'12px',
              fontWeight: 'normal',
              fontSize: '24px',
              textDecoration: 'noopener',
            }}/>
        
      </div>
      </>
    );
  }
  