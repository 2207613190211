import { doc, updateDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { db } from '../../../../config/firebase';
import { _fetchUsers } from '../../../../redux/actions/user';

export const ModalSelectExam = ({ isOpen, closeModal, examen }) => {

    const [numeroColaboradores, setNumeroColaboradores] = useState(5);
    const [simulacrosTotales, setSimulacrosTotales] = useState(5);

    const [users, setUsers] = useState([]);
    
    useEffect(() => {
        const fetchUsers = async () => {
            const { users } = await _fetchUsers();
            setUsers(users);
        };

        fetchUsers();
    }, []);

    const handleSelectExam = async () => {
        try {
            const datosDocRef = doc(db, "dataPage", "datos");

            await updateDoc(datosDocRef, {
                examenCpmpleto: examen,
                tituloBanner: examen.data.nombre,
                fechaExamen: examen.data.fecha,
                colaboradores: numeroColaboradores,
                simulacrosTotales: simulacrosTotales,
                clientesActuales: users.length
            });

            alert('El examen ha sido seleccionado exitosamente.');
            
        } catch (error) {
            console.error("Error al actualizar el examen:", error);
            alert("Error al seleccionar el examen. Inténtalo de nuevo.");
        }
        closeModal();
    };

    const handleInputChange = (e) => {
        setNumeroColaboradores(e.target.value);
    };

    const handleInputSimulacrosChange = (e) => {
        setSimulacrosTotales(e.target.value);
    };

    if (!isOpen) return null;
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-6 rounded-lg max-w-full">

                <p className='font-semibold text-xl text-center'>¿Estás seguro de que quieres seleccionar el examen</p>
                <p className='font-semibold text-xl text-center'>{examen.data.nombre}?</p>

                <div className="mt-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">Número de colaboradores:</label>
                    <input
                        type="number"
                        value={numeroColaboradores}
                        onChange={handleInputChange}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                </div>

                <div className="mt-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">Número de simulacros totales:</label>
                    <input
                        type="number"
                        value={simulacrosTotales}
                        onChange={handleInputSimulacrosChange}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                </div>

                <div className="flex justify-end mt-4">
                    <button
                        type='button'
                        onClick={closeModal}
                        className="bg-gray-500 text-white py-2 px-4 rounded mr-2"
                    >
                        Cancelar
                    </button>
                    <button
                        type="button"
                        className="bg-blue-500 text-white py-2 px-4 rounded"
                        onClick={handleSelectExam}  // Llama a la función para actualizar el campo examenCompleto
                    >
                        Sí
                    </button>
                </div>
            </div>
        </div >
    )
}
