import React,{useState, useEffect} from 'react';
import backgroun from './../../assets/images/abogados.jpg'
import { FaRegBookmark} from 'react-icons/fa';
//import { Carousel } from 'react-responsive-carousel';
import { Carousel } from './carousel/components';
//import {images} from './carousel/mock';
import {db} from '../../config/firebase';
import { collection, getDocs } from "firebase/firestore";


export default function Comments(props){

  const [images , setimages] = useState([]);
  const [comentarios, setComentarios] = useState([]);


  useEffect(()=>{
    getDocs(collection(db,'galeria'))
    .then((response)=>{
        let img =[]
        response.forEach((doc)=>{
          img.push(doc.data());
        });
        return img;
    }).then( doc =>{setimages(doc)})
  },[])

  useEffect(()=>{
    getDocs(collection(db,'comentarios'))
    .then((response)=>{
        let comment =[]
        response.forEach((doc)=>{
          comment.push(doc.data());
        });
        return comment;
    }).then( doc =>{setComentarios(doc)})
  },[])



  return(
    props.tema === 'claro'?
    <>
     <div className = "p-8 items-center sm:p-5 md:p-8 bg-white">
         
     <div className="flex flex-row justify-start sm:flex-col md:flex-row">
        <div  className="flex flex-row justify-center w-5/12  sm:w-full md:w-5/12">
         <Carousel time={5000}>
         { 
           images.map((image, index) => 
             <div
             className=" bg-black w-full border-2 border-white"
             key={index}>
               <img src={image.url} alt={image.alt} />
               <p className="text-center font-regular text-white leading-10 p-5 w-full">{image.titulo}</p>
             </div>
           )
         }
       </Carousel>
          {/* <h3
            className="text-primary text-2xl font-bold leading-relaxed"
          >Beneficios Apriori</h3>
          <ul
            className="ml-4"
          > 
            <li type="disc" className=' text-xl mt-2'>Exámenes diseñados estrictamente con base en los ejes temáticos oficiales.</li>
            <li type="disc" className=' text-xl mt-2'>Preguntas elaboradas por especialistas que ocupan u ocuparon el cargo al que postulas.</li>
            <li type="disc" className=' text-xl mt-2'>Tendrás revisión de cada examen que des, PREGUNTA POR PREGUNTA. </li>
            <li type="disc" className=' text-xl mt-2'>Gráficos que te indicaran tu progreso y te ayudaran a identificar en qué materias necesitas reforzar.</li>
            <li type="disc" className=' text-xl mt-2'>Soporte personalizado. </li>
            <li type="disc" className=' text-xl mt-2'>Disponibilidad 24/7.</li>
          </ul> */}
        </div>
        <div className=" w-6/12  sm:w-full md:w-6/12 p-4 ">
        <h3
            className="text-primary text-2xl font-bold leading-relaxed"
          >Testimonio de Clientes</h3>
      <h2 className="font-medium text-4xl leading-10 my-5 w-2/4 sm:w-full nd:w-full sm:text-base md:text-xl text-left sm:text-center md:text-left">Comprueba lo que dicen nuestros clientes sobre nosotros</h2>
      
        <Carousel time={5000}>
        { 
          comentarios.map((image, index) => 
            <div 
            className="flex flex-row justify-start items-start w-full p-8 sm:justify-center sm:items-center sm:w-full md:w-full sm:p-4 md:p-4 sm:flex-col md:flex-row"
            key={index}>
              <FaRegBookmark className="text-primary text-3xl self-start "/>
              <div className="ml-5 text-left sm:text-center md:text-left sm:ml-0 md:ml-3">
                  <p className="font-medium text-xl leading-10 pl-3 w-full sm:pl-0 md:pl-3">{image.message}</p>
                  <p className="font-medium pt-5 pl-3  sm:pl-0 md:pl-3">{image.autor}</p>
                  <p className="font-bold pl-3  sm:pl-0 md:pl-3">{image.cargo}</p>
              </div>
              
              
            </div>
          )
        }
      </Carousel>
        </div>

      
      </div>
      
     </div>
    </>:
    <>
    <div className = "p-20 items-center bg-fondo sm:p-5 md:p-8">
        
     <h1 className="text-primary text-xl sm:text-center md:text-left">Testimonio de Clientes</h1>
     <h2 className="font-medium text-4xl leading-10 my-5 w-2/4 text-white  sm:w-full md:w-2/4 sm:text-base md:text-xl text-left sm:text-center md:text-left">Comprueba lo que dicen nuestros clientes sobre nosotros</h2>
     <div className="flex flex-row justify-start sm:flex-col md:flex-row">
       <div  className="flex flex-row justify-center w-5/12  sm:w-full md:w-5/12">
         <Carousel time={5000}>
         { 
           images.map((image, index) => 
             <div
             className=" bg-black w-full border-2 border-white"
             key={index}>
               <img src={image.url} alt={image.alt} />
               <p className="text-center font-regular text-white leading-10 p-5 w-full">{image.titulo}</p>
             </div>
           )
         }
       </Carousel>
       </div>
       <div className=" w-6/12 sm:w-full md:w-6/12 a">
       <Carousel time={5000}>
       { 
         comentarios.map((image, index) => 
           <div 
           className="flex flex-row justify-start items-start w-full p-8 h-full"
           key={index}>
             <FaRegBookmark className="text-primary text-3xl"/>
             <div className="ml-5 sm:ml-0 md:ml-3">
                 <p className="font-medium text-xl sm:text-base md:text-xl leading-10 pl-3 w-full text-white sm:pl-0 md:pl-3">{image.message}</p>
                 <p className="font-medium pt-5 pl-3 text-white  sm:pl-0 md:pl-3">{image.autor}</p>
                 <p className="font-bold pl-3 text-white  sm:pl-0 md:pl-3">{image.cargo}</p>
             </div>
             
             
           </div>
         )
       }
     </Carousel>
       </div>

     
     </div>
     
    </div>
   </>
    );
};
