import React ,{Fragment, useEffect, useState} from 'react';
import Footer from '../components/footer';
import ExamenCard from './components/ExamenCard';
import { useDispatch, useSelector } from 'react-redux';
import { getQuestion } from '../../redux/actions/exam';
import {OnLoad, Bar as BarLoad, Dots} from '../components/onload';
import { startLogOut } from '../../redux/actions/auth';
import {auth, db} from '../../config/firebase'
import { collection, getDocs } from "firebase/firestore";
import { doc, getDoc, addDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import dbRef from '../../config/dbRef';
import book from '../../assets/images/book.png';
import userApriori from '../../assets/images/aprioriUser.png';
import { FaWhatsapp, FaEnvelope, FaFacebookF, FaInstagramSquare} from "react-icons/fa";
import {BiTask,BiPhoneCall} from 'react-icons/bi';
import {MdEventNote, MdSource} from 'react-icons/md';
import {ImStatsDots} from 'react-icons/im';
import { Link } from 'react-router-dom';
import Modal from '../components/Modal';
//import { Chart as ChartJS } from 'chart.js/auto'

import { Line, Bar } from "react-chartjs-2";
import {Chart,CategoryScale,LinearScale,PointElement,LineElement,Title,Tooltip,Legend,BarElement} from 'chart.js'

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Respuestas del ultimo examen',
    },
  },
};


Chart.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

const EventsCard = (props) => {
  const {fecha, contenido, expositor, modalidad, nombre, url} = props.data.data;
  const [isOpen, setIsOpen] = useState(false);
  console.log(props.data)
  return (
    <>
     <Modal visible={isOpen} title="Apriori Eventos." onClose={()=>setIsOpen(false)}>
       <div className='text-center'>
        <h1 className='text-primary my-4'>Bienvenido a Apriori - Eventos, una nueva forma de aprender </h1>
        <p>Le damos la bienvenida al evento <b>{nombre}</b>, impartido por el {expositor}.</p>
        <p>Para una mejor experiencia le recomendamos tener buena conexion a internet, agüita o cafe y estar comodos</p>
        <div className='my-8'>
          {/* {<Link to={url} target='_blank' className="bg-primary text-white py-2 px-4 my-16 rounded-md" >Comenzar</Link>} */}
          <Link className="bg-primary text-white py-2 px-4 my-16 rounded-md" to={{
          pathname: `/Live`,
          data: props.data,
        }}>Comenzar</Link>
        </div>
        
       </div>
       
       
     </Modal>
      <div className=" flex flex-col m-3 justify-between items-center text-black border-accent border-2 w-full max-w-45  md:max-w-45 sm:max-w-full rounded-md">
      <div className=" flex flex-row ">
        <div className="flex flex-col justify-start w-2/4 "> 
        <h1 className="bg-accent text-white text-center py-2 p-2">{nombre}</h1>
        <div className="px-3">
          
          <p className="text-start text-primary">Fecha de evento: <span className="text-black">{fecha}</span> </p>
          <p className="text-start text-primary">Contenido: <span className="text-black"></span> </p>
            <ol className="pl-5">
              {
                contenido.map((item, index) => {
                  return <li type="square" key={index}>{item}</li>
                }
                )
              }
            </ol>
          </div>
        </div>
        <img className="border-white w-2/4 rounded-tr-md" src={url} alt=""/>
      </div>
      <div className="bg-gray-200 w-full justify-center flex flex-col px-3 py-2 rounded-b-md">
        {
          expositor.length === 1 ?
          <p className="text-start text-primary">Expositor</p>
          :
          <p className="text-start text-primary">Expositores:</p>
        }
        <ol className="pl-5 text-black">
          {
            expositor.map((item, index) => {
              return <li key={index}>{item}</li>
            }
            )
          }
        </ol>
          
        <p className="text-start text-primary">Medio: <span className="text-black pl-1">{modalidad}</span> </p>

        <button className="bg-primary text-white py-2 px-4 mt-3 rounded-md" onClick={() => setIsOpen(!isOpen)}>
          {'Ingresar'}
        </button>
      </div>

      </div>
    </>
  );
}

export default function Profile(){
    const reduxUser = useSelector(state => state.user);
    const reduxExam = useSelector(state => state.exam);
    const dispatch = useDispatch();
    const materias  = [ 'Penal', 'Carcel', 'Familia','Sin materia'];
    let cantMaterias = [];
    const [windowActive, setWindowActive] = useState('exams');
    const [exams, setExams] = useState([]);
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState({});
    const [mensaje, setMensaje] = useState('Bienvenido a Apriori');
    const [pruebas, setPruebas] = useState();
    const [indexContent, setIndexContent] = useState([]);
    const [mensajeContacto, setMensajeContacto] = useState('');
    const [eventos , setEventos] = useState([]);
    const [userEvents, setUserEvents] = useState([]);

    //buscar el elemento de tipo iframe y eliminarlo
  

    const sendWhatsapp = (telefono) => {
      
      //salto de linea en el mensaje
      let mensaje = `Apriori, Datos de cuenta: \n Monto: `;
      let url = `https://api.whatsapp.com/send?phone=${telefono}&text=${mensajeContacto}`;
      window.open(url, '_blank');
    
    }
  
    const logOut = () => {
      dispatch(startLogOut());
    }
    
    //console.log(store);
    useEffect(() => {
      document.title =`Apriori-${user.name?user.name:''}`;
      materias.forEach(materia => {
        const nuevaMateria = {
          materia: materia,
          cantidad: 0
        };
        cantMaterias.push(nuevaMateria);
      })
      console.log(cantMaterias);
    }, [])
    //metodo asincrono para obtener los examenes
    // const getEvents = async () => {
    //   const cuenta = doc(db, dbRef.user, auth.currentUser.uid);
    //   const eventsSnap = await getDocs(collection(cuenta, "Cursos"));

    //   let eventsData = [];
    //   eventsSnap.forEach(doc => {
    //     eventsData.push({id:doc.id,...doc.data()});
    //   });
    //   setUserEvents(eventsData);
    // }
    
    useEffect(()=>{
      getDocs(collection(db,'Cursos'))
      .then((response)=>{
          let event =[]
          response.forEach((doc)=>{
            event.push(
              {
                id: doc.id,
                data:doc.data()
              }
              );
          });
          return event;
      }).then( doc =>{console.log(doc); setEventos(doc)})
      .catch(err => console.log(err));
      //getEvents();
    },[])


    //useeffect de data de examenes
    useEffect(() => {
      if(reduxUser){
        const pruebas = [];
        const cuenta = reduxUser.cuenta;
        console.log(cuenta);
        if (cuenta){
          let index = 0;
          let type = [];
          cuenta.forEach(exam => {
            pruebas.push(exam);
            if (index === 0){
              type.push('flex');
            }else{
              type.push('none');
            }
            index++;
          });
          setIndexContent(type);
          setPruebas(pruebas);  
        }
        
      }
      
    }, [reduxUser])

    const bar = document.getElementById('bar');
    if (bar) {
      bar.style.display = 'block';
    }

    useEffect(() => {
      setUser(reduxUser.user);
      console.log(user);
    }, [reduxUser.user])

    useEffect(() => { 
      if(reduxUser.user){
        setLoading(false);
      }else{
        setLoading(true);
      }
      console.log('se ejecuto useEffect');
    }, [reduxUser])

    useEffect(() => {
      if(reduxUser.cuenta){
        console.log("obteniendo preguntas de la cuenta");
        setExams(reduxUser.cuenta);
        dispatch(getQuestion(auth.currentUser.uid));
        /*
        reduxUser.cuenta.map((exam) => {
          console.log(reduxExam.exams);
          console.log(exam.id);
          dispatch(getQuestion(exam.idExamen,exam.plan, exam.id));

        })*/

      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reduxUser.cuenta])

    useEffect(() => {
      if(reduxExam.exams){
        console.log("preguntas de exams");
        console.log(reduxExam.exams);
      }
    }, [reduxExam.exams])

    const changeContent = (param) => {
      let newArray = [];
      indexContent.forEach((item, index) => {
        if (index === param){
          newArray.push('flex');
        }else{
          newArray.push('none');
        }
      })
      setIndexContent(newArray);
    }
    

    return(
    <>
      <div className='bg-primary fixed sm:fixed md:hidden mt-full flex flex-row w-full text-white w-full justify-between px-8 sm:px-3 md:px-4 rounded-b-lg'
      style={{position:'fixed',bottom:'0px',zIndex:999999 }}
      >
        <p className={`flex flex-col items-center mt-2 py-2 pb-8 px-3 sm:px-1 md:px-2 cursor-pointer hover:text-secundary rounded-t-xl text-lg sm:text-base md:text-lg  ${windowActive === 'exams'?' bg-white text-primary':'text-white'}`} 
        onClick={() => setWindowActive('exams')}
        
        ><BiTask className='text-3xl'/><span className='text-xs'>Examenes</span></p>
        <p className={`flex flex-col items-center mt-2 py-2 px-3 sm:px-1 md:px-2 cursor-pointer hover:text-secundary rounded-t-xl text-lg sm:text-base md:text-lg ${windowActive === 'events'?' bg-white text-primary':'text-white'}`}
        onClick={() => setWindowActive('events')}
        ><MdEventNote className='text-3xl'/><span className='text-xs'>Eventos</span></p>
        <p className={`flex flex-col items-center mt-2 py-2 px-3 sm:px-1 md:px-2 cursor-pointer hover:text-secundary rounded-t-xl text-lg sm:text-base md:text-lg ${windowActive === 'history'?' bg-white text-primary':'text-white'}`}
        onClick={() => setWindowActive('history')}
        ><ImStatsDots className='text-3xl'/> <span className='text-xs'>Resultados</span> </p>
        <p className={`flex flex-col items-center mt-2 py-2 px-3 sm:px-1 md:px-2 cursor-pointer hover:text-secundary rounded-t-xl text-lg sm:text-base md:text-lg ${windowActive === 'sources'?' bg-white text-primary':'text-white'}`}
        onClick={() => setWindowActive('sources')}
        ><MdSource className='text-3xl'/> <span className='text-xs'>Recursos</span> </p>
        <p className={`flex flex-col items-center mt-2 py-2 px-3 sm:px-1 md:px-2 cursor-pointer hover:text-secundary rounded-t-xl text-lg sm:text-base md:text-lg ${windowActive === 'contact'?' bg-white text-primary':'text-white'}`}
        onClick={() => setWindowActive('contact')}
        ><BiPhoneCall className='text-3xl'/><span className='text-xs'>Soporte</span> </p>
      </div>
      
      <div className="w-full text-white flex flex-col justify-center items-center border-b-16 border-accent" style={{backgroundImage:`url(${book})`,backgroundSize:'cover',backgroundPosition:'bottom' }}>
        <h1 className="text-3xl my-5  sm:text-xl md:text-3xl mx-0 sm:mx-4 md:mx-0 text-center ">{mensaje}</h1>
      </div>

      <div className='flex flex-row items-start justify-center w-full h-full px-28 sm:px-2 md:px-16'>
        <div className='w-3/12 flex flex-col justify-center items-center pb-16 sm:hidden md:flex'>
        {
              user?
                user.photo?
                  <img className="mt-4 border-8 border-gray-200 w-3/4" src={user.photo} alt="no photo"/>
                  :
                  <img className="mt-4 border-8 border-gray-200 w-3/4" src={userApriori} alt="no photo"/>  
              :
              <img className="mt-4 border-8 border-gray-200 w-3/4" src={userApriori} alt="no photo"/>  
            }
            <h2 className='mt-8 text-3xl text-primary'>{user && user.name}</h2>
            <h3>{user && user.lastName}</h3>
            <p>{user && user.email}</p>
            {/* <p>{auth.currentUser.uid && auth.currentUser.uid}</p> */}
            <div className="flex flex-col justify-center items-center border border-accent mx-5 mt-5 rounded-t-xl ">
              <div className="flex flex-col justify-center bg-accent rounded-t-xl w-full py-3"><h2 className="text-center text-white">Mensajes</h2></div>
              <div className="flex flex-col justify-center items-center ">
                {
                  user?
                    user.mensajes?
                      user.mensajes.map((mensaje, index) => {
                        if (index %2==0 ){
                          return <p key={index} className="text-center bg-gray-300 w-full p-2">{mensaje}</p>
                        }else{
                          return <p key={index} className="text-center bg-gray-100 w-full p-2">{mensaje}</p>
                        }
                      })
                    :
                    <p>No tiene mensajes</p>
                  :
                  <p>No tiene mensajes</p>
                }
              </div>
            </div>

        </div>

        <div className='w-9/12 h-80 flex flex-col justify-start items-start -mt-1 sm:mt-1 md:-mt-1 w-full'>
          <div className='bg-accent flex flex-row w-full text-white w-full justify-between px-8 rounded-b-lg sm:hidden md:flex'>
            <p className={`py-2 px-3 sm:px-1 md:px-2 cursor-pointer hover:text-secundary rounded-t-xl text-lg sm:text-base md:text-lg ${windowActive === 'exams'?' bg-white text-primary':'text-white'}`} 
            onClick={() => setWindowActive('exams')}
            >Examenes</p>
            <p className={`py-2 px-3 sm:px-1 md:px-2 cursor-pointer hover:text-secundary rounded-t-xl text-lg sm:text-base md:text-lg ${windowActive === 'events'?' bg-white text-primary':'text-white'}`}
            onClick={() => setWindowActive('events')}
            >Eventos</p>
            <p className={`py-2 px-3 sm:px-1 md:px-2 cursor-pointer hover:text-secundary rounded-t-xl text-lg sm:text-base md:text-lg ${windowActive === 'history'?' bg-white text-primary':'text-white'}`}
            onClick={() => setWindowActive('history')}
            >Resultados</p>
            <p className={`py-2 px-3 sm:px-1 md:px-2 cursor-pointer hover:text-secundary rounded-t-xl text-lg sm:text-base md:text-lg ${windowActive === 'sources'?' bg-white text-primary':'text-white'}`}
            onClick={() => setWindowActive('sources')}
            >Recursos</p>
            <p className={`py-2 px-3 sm:px-1 md:px-2 cursor-pointer hover:text-secundary rounded-t-xl text-lg sm:text-base md:text-lg ${windowActive === 'contact'?' bg-white text-primary':'text-white'}`}
            onClick={() => setWindowActive('contact')}
            >Soporte</p>
          </div>
          
          <div className={`w-full h-full pt-8 px-0 justify-center flex-wrap max-h-screen overflow-auto ${windowActive === 'exams'?'flex flex-row':'hidden'}`}>
          {
            loading?
            <div className='w-full flex flex-col justify-center'>
              <p className="text-2xl text-center text-primary">
              <Dots color={'gray'}/></p>
              <h1  className='w-full text-center'>Cargando...</h1>
            </div>
              : exams.map((examen, index) => {
                  return(
                  <Fragment key={index}>
                    <ExamenCard key={index} plan={examen.plan} idCuenta={examen.id} data={examen}/>
                  </Fragment>)
                }
            )
          }
          </div>
          <div className={`w-full h-full pt-8 px-0 justify-center flex-wrap max-h-screen overflow-auto ${windowActive === 'events'?'flex flex-row':'hidden'}`}>
          {
            eventos.map((evento, index) => {
              return(
              <Fragment key={index}>
                <EventsCard key={index} data={evento}/>
              </Fragment>)
            }
            )
          }
          </div>
          <div className={`w-full h-full pt-8 px-0 justify-center flex-wrap max-h-screen overflow-auto ${windowActive === 'history'?'flex flex-row':'hidden'}`}>
            <div className="flex flex-row justify-center items-center w-full flex-wrap">
              {
                //estadisticas
                pruebas?
                pruebas.map((prueba, index) => {
                  return(
                    <Fragment key={index}>
                      <div className={`w-1/4 text-center border-2 border-primary m-2 rounded p-2 cursor-pointer ${indexContent[index] !=='flex'?'text-primary bg-transparent':'text-white bg-primary'}`}
                      onClick={()=>{changeContent(index)}}
                      >
                        <p className={`border-b-2 ${indexContent[index] ==='flex'?'border-white':'border-primary'}`}>{prueba?prueba.nombre:'no data'}</p>
                        <p>{prueba?
                        prueba.tipo==='PFinal'?
                        'Prueba Final':
                        'Prueba practica':
                        'no data'}</p>
                      </div>
                    </Fragment>
                    
                  )
                }) :
                
                <div className='w-full flex flex-col justify-center'>
                  <p className="text-2xl text-center text-primary">
                    no hay pruebas
                  </p>
                  <h1  className='w-full text-center'>No hay pruebas</h1>
                </div>

              }
            </div>
            {
              pruebas?
              pruebas.map((prueba, index) => {
                return(
                  <Fragment key={index}>
                    <div
                    className='w-full flex flex-col justify-center items-center'
                      style={{display:`${indexContent[index]}`}}
                    >
                      <p className='w-full border-primary border-t-2 text-black mb-2 mt-5 text-center'>
                        Estadisticas de respuestas correctas e incorrectas del ultimo intento realizado.
                      </p>
                      {pruebas[index].notas?
                      <Bar options={options} data={{
                        labels : pruebas[index]?pruebas[index].notas[pruebas[index].notas.length-1].notas.map((materia, index)=>{return materia.materia}):[],
                        datasets: [
                          {
                            label: 'Correctos',
                            data: pruebas[index]?pruebas[index].notas[pruebas[index].notas.length-1].notas.map((materia, index)=>{return materia.correctas}):[],
                            backgroundColor: 'rgba(53, 162, 235, 0.5)',
                          },
                          {
                            label: 'Incorrectos',
                            data:  pruebas[index]?pruebas[index].notas[pruebas[index].notas.length-1].notas.map((materia, index)=>{return materia.incorrectas}):[],
                            backgroundColor: 'rgba(255, 99, 132, 0.5)',
                          },
                        ],
                      }} />:<p className='text-primary'>No tiene notas en este examen</p>
                      }
                      <p className='w-full border-primary border-t-2 text-black mb-2 mt-5 text-center'>
                        Estadisticas de notas de todos los intentos realizado.
                      </p>
                      {pruebas[index].notas?
                        <Line data={{
                          labels: pruebas[index]?pruebas[index].notas.map((materia, index)=>{return 'intento '+(index+1)}):[],
                          datasets: [
                            {
                              label: "Notas Generales",
                              data: pruebas[index]?pruebas[index].notas.map((materia, index)=>{return materia.calificacion}):[],
                              fill: true,
                              backgroundColor: "#e8452909",
                              borderColor: "#e84529"
                            },
                          ]}} />
                        :<p className='text-primary'>No tiene notas en este examen</p>
                      }
                    </div>
                  </Fragment>
                  
                )
              }) :
              <p>No data</p>
            }  
          </div>
          <div className={`w-full h-full pt-8 px-0 justify-center flex-wrap max-h-screen overflow-auto ${windowActive === 'sources'?'flex flex-row':'hidden'}`}>
            <div className='flex flex-col items-start justify-center mt-4 mx-4 '>
            <p className='text-center font-bold text-primary text-xl'>Tus recursos disponibles: </p>
              <ul>
                {user?
                  user.recursos?
                  user.recursos.map((recurso,index)=>{
                    return(
                      <li type='disc' className='ml-8' >
                        <a href={recurso.url} target='_blank' rel="noopener noreferrer" className='mx-1 text-start text-black'>{recurso.nombre}</a>
                      </li>
                    )
                      })
                    :
                    <li>No cuenta con Recursos en este momento</li>
                  :
                  null
                }
              </ul>
              
            </div>
          </div>
          <div className={`w-full h-full pt-8 px-0 justify-center flex-wrap max-h-screen overflow-auto ${windowActive === 'contact'?'flex flex-row':'hidden'}`}>
          <h1>Hola {user?user.name:null}, en que podemos ayudarte:</h1>
                <div className="flex flex-row justify-center items-start mt-5 w-full">
                  <div className="flex flex-col justify-center items-center  w-full mr-8">
                    <div className='flex self-start justify-start items-center bg-gray-300 mt-2 w-full'>
                      <a href='https://wa.me/59161885865' target='_blank' rel="noopener noreferrer" className='text-primary'>
                        <FaWhatsapp className='bg-green-500 text-white p-2 ' size={40}/>
                      </a>
                      <a href='https://wa.me/59161885865' target='_blank' rel="noopener noreferrer" className='text-primary'>
                        <FaEnvelope className='bg-blue-600 text-white p-2 ' size={40}/>
                      </a>
                      <p className='px-3 text-center text-black'>Leonel de Sistemas</p>
                    </div>
                    <div className='flex self-start justify-start items-center bg-gray-300 mt-2 w-full'>
                      <a href='https://wa.me/59161885865' target='_blank' rel="noopener noreferrer" className='text-primary'>
                        <FaWhatsapp className='bg-green-500 text-white p-2 ' size={40}/>
                      </a>
                      <a href='https://wa.me/59161885865' target='_blank' rel="noopener noreferrer" className='text-primary'>
                        <FaEnvelope className='bg-blue-600 text-white p-2 ' size={40}/>
                      </a>
                      <p className='px-3 text-center text-black'>Abigail de administracion</p>
                    </div>
                    {/* <div className='flex self-start justify-start items-center bg-gray-300 mt-2 w-full'>
                      <a href='https://wa.me/59161885865' target='_blank' rel="noopener noreferrer" className='text-primary'>
                        <FaWhatsapp className='bg-green-500 text-white p-2 ' size={40}/>
                      </a>
                      <a href='https://wa.me/59161885865' target='_blank' rel="noopener noreferrer" className='text-primary'>
                        <FaEnvelope className='bg-blue-600 text-white p-2 ' size={40}/>
                      </a>
                      <p className='px-3 text-center text-black'>Valeria de Finanzas</p>
                    </div> */}
                    <div className='flex self-start justify-start items-center bg-gray-300 mt-2 w-full'>
                      <a href='https://wa.me/59161885865' target='_blank' rel="noopener noreferrer" className='text-primary'>
                        <FaWhatsapp className='bg-green-500 text-white p-2 ' size={40}/>
                      </a>
                      <a href='https://wa.me/59161885865' target='_blank' rel="noopener noreferrer" className='text-primary'>
                        <FaEnvelope className='bg-blue-600 text-white p-2 ' size={40}/>
                      </a>
                      <p className='px-3 text-center text-black'>Rimber de sistemas</p>
                    </div>
                  </div>
                  <div className="flex flex-col justify-start items-end w-full sefl-start ml-4">
                    <div className='flex self-end justify-end items-center bg-green-300 mt-2 w-full'>
                      <p className='px-3 text-center text-black'>Apriori Whatsapp</p>
                      <a href='https://wa.me/+59161885865' target='_blank' rel="noopener noreferrer" className='text-primary'>
                        <FaWhatsapp className='bg-green-500 text-white p-2 ' size={40}/>
                      </a>
                    </div>
                    <div className='flex self-end justify-end items-center bg-red-300 mt-2 w-full'>
                      <p className='px-3 text-center text-black'>Apriori Email</p>
                      <a className="text-primary" href="mailto:apriori.bolivian@gmail.com" >
                       <FaEnvelope className='bg-red-500 text-white p-2 ' size={40}/>
                      </a>
                      
                    </div>
                    <div className='flex self-end justify-end items-center bg-blue-300 mt-2 w-full'>
                      <p className='px-3 text-center text-black'>Apriori Facebook</p>
                      <a href="https://m.me/aprioribol" target='_blank' rel="noopener noreferrer" className='text-primary'>
                        <FaFacebookF className='bg-blue-500 text-white p-2 ' size={40}/>
                      </a>
                    </div>
                    <div className='flex self-end justify-end items-center bg-pink-300 mt-2 w-full'>
                      <p className='px-3 text-center text-black'>Apriori Instagram</p>
                      <a href="https://in.me/aprioribol" target='_blank' rel="noopener noreferrer" className='text-primary'>
                        <FaInstagramSquare className='bg-pink-500 text-white p-2 ' size={40}/>
                      </a>
                    </div>
                  </div>
                </div>

                <div className='flex flex-col items-center mt-8'>
                <p className='my-1 text-center'>Tuviste algun incoveniente con la plataforma? </p>
                <textarea  onChange={(e)=>{setMensajeContacto(e.target.value)}}  className='w-2/3 border-2 border-red-400 p-2 rounded my-3' placeholder='Escribe aqui tu mensaje'></textarea>
                  <div className='flex justify-center' >
                  <a href={`https://wa.me/+59175413873?text=${mensajeContacto}`} target='_blank' rel="noopener noreferrer" className='my-2 bg-primary p-2 rounded text-white px-3'>Enviar a Soporte
                      </a>
                  </div>
                  
                </div>
               
                <div div="flex flex-col w-full justify-center bg-red-200"> 
                  
                </div>
          </div>
        </div>
      </div>
      <Footer contact='not'/>
    
    </>
    );
};
